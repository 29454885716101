export default {
  '粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:':
    ' ចម្លងលីងទំនិញក្នុងគេហទំព័រចិនឬឈ្មោះផលិតផល',
  信息: 'សារ',
  请输入: 'សូមបញ្ចូល',
  收货人: 'អ្នកទទួល',
  联系电话: 'លេខទំនាក់ទំនង',
  邮政编码: 'លេខកូដតំបន់',
  确定: 'ប្រាកដ',
  取消: 'បោះបង់',
  联系人: 'អ្នកទាក់ទង',
  地址: 'អាសយដ្ឋាន',
  不能为空: 'មិនអាចទទេ',
  客服介入: 'សេវាបម្រើអតិថិជនចូលរួម',
  录入包裹: 'បញ្ចូលកញ្ចប់ទំនិញ',
  请选择: 'សូមជ្រើសរើស',
  三月内: 'ក្នុងពេល3ខែ',
  半年内: 'ក្នុងពេលកន្លះឆ្នាំ',
  一年内: 'ក្នុងពេល1ឆ្នាំ',
  全部: 'ទាំងអស់',
  催单成功: 'បន្តឿនបុងបានសម្រេច ',
  已收到货: 'បានទទួលទំនិញ',
  最少6位: 'យ៉ាងតិច6ខ្ទង់',
  '支付完成后,请根据您支付的情况点击下面按钮':
    'បន្ទាប់ពីបង់ប្រាក់បានសម្រេច,សូមជ្រើសរើសបូតុងខាងក្រោមទៅតាមការបង់ប្រាក់របស់អ្នក',
  支付遇到问题: 'មានបញ្ហាក្នុងការ​បង់ប្រាក់',
  支付完成: 'បង់ប្រាក់បានសម្រេច',
  不能小于5或大于150个字符: 'មិនអាចមានតិចជាង5ខ្ទង់ឬច្រើនជាង150ខ្ទង់',
  已保存: 'បានរក្សាទុក',
  请选择充值金额: 'សូមជ្រើសរើសចំនួនទឹកប្រាក់ដែលត្រូវបញ្ចូល',
  请输入用户名: 'សូមបញ្ចូលឈ្មោះអតិថិជន',
  请输入验证码: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់',
  加入购物车中: 'បញ្ចូលក្នុងរទេះទំនិញ',
  继续: 'បន្ដ',
  购物车详情: 'ព័ត៌មានលម្អិតពីរទេះទំនិញ',
  电话: 'លេខទូរស័ព្ទ',
  邮箱: 'ប្រអប់សំបុត្រ',
  注册成功: 'ចុះឈ្មោះបានសម្រេច',
  超出提交最大数量: 'ចំនួនអតិបរមានៃការដាក់ស្នើបានលើស ',
  确认: 'ប្រាកដ',
  已删除: 'បានលុបចោល',
  关于乐达: 'អំពីឡូដា LODA ',
  加入乐达: 'ចូលរួមជាមួយឡូដាLODA',
  关于: "អំពីឡូដា ",
  加入: "ចូលរួមជាមួយឡូដា",
  服务协议: 'កិច្ចព្រងព្រៀងសេវាកម្ម',
  服务支持: 'ការគាំទ្រសេវាកម្ម',
  购物指南: 'ការណែនាំពីទំនិញ',
  如何下单: 'របៀបបញ្ជាទិញ',
  联系方式: 'ការទំនាក់ទំនង',
  站点查询: 'ឆែករកគេហទំព័រ',
  工作时间: 'ម៉ោងធ្វើការ',
  周一至周五: 'ថ្ងៃចន្ទដល់ថ្ងៃសុក្រ',
  周六至周日: 'ថ្ងៃសៅរ៍ដល់ថ្ងៃអាទិត្យ',
  选择部门: 'ជ្រើសរើសផ្នែក',
  无查询结果: 'ស្វែងរកមិនឃើញ',
  筛选: 'ជ្រើសរើស',
  价格: 'តម្លៃ',
  FilterChange: 'ជ្រើសរើស',
  套用: 'សម្រាប់ប្រើប្រាស់',
  首页: 'ទំព័រដើម',
  卖家中心: 'មជ្ឈមណ្ឌលអ្នកលក់',
  网站导航: 'ការណែនាំអំពីគេហទំព័រ',
  logout: 'ចាកចេញ',
  退出: 'បោះបង់ចេញ',
  海外购: 'ការបញ្ជាទិញក្រៅស្រុក',
  本地商城: 'ហាងទំនិញក្នុងស្រុក',
  店铺: 'ហាងទំនិញ',
  '粘贴需要代购的中国购物网站的商品网址或者商品名称、':
    'ចម្លងលីងទំនិញក្នុងគេហទំព័រចិនឬឈ្មោះផលិតផល',
  例子: 'ឧទាហរណ៍',
  搜索店铺: 'ស្វែងរកហាង',
  支持商家: 'គាំទ្រហាង',
  阿里巴巴: '1688',
  淘宝: ' TaoBao',
  天猫: 'Tianmao',
  京东: 'Jing dong ',
  拼多多: 'Ping duo duo ',
  所有类别: 'ប្រភេទទាំងអស់',
  商品: 'ទំនិញ',
  评价: 'វាយតម្លៃ',
  聊天回应率: 'ការឆ្លើយតប',
  回应速度: 'ល្បឿននៃការឆ្លើយតប',
  运输方式: 'ការដឹកជញ្ជូន',
  包裹类目: 'ប្រភេទកញ្ចប់ទំនិញ',
  长度: 'បណ្ដោយ',
  '(CM)': 'សង់ទីម៉ែត្រ',
  宽度: 'ទទឹង',
  高度: 'កម្ពស់',
  重量: 'ទម្ងន់',
  '(KG)': 'គីឡូក្រាម',
  数量: 'ចំនួន',
  查询: 'ស្វែងរក',
  运费估算: 'ប៉ានស្មានថ្លៃដឹកជញ្ជូន',
  仓库地址: 'អាសយដ្ឋានឃ្លាំង',
  包裹信息: 'ព័ត៌មានពីកញ្ចប់ទំនិញ',
  新增包裹: 'បន្ថែមកញ្ចប់ទំនិញ',
  快递单号: 'លេខកូដប្រអប់ទំនិញ',
  货物名称: 'ឈ្មោះទំនិញ',
  货物数量: 'ចំនួនទំនិញ',
  '保险服务(申报价值)': 'ធានារ៉ាន់រង(តម្លៃដែលបានប្រកាស)',
  最大货值金额: 'ទឹកប្រាក់អតិបតមា',
  保险费: 'ថ្លៃធានារ៉ាប់រង',
  木架加固服务: 'សេវាដាក់បន្ថែកស៊ុមការពារ',
  备注: 'សុន្ទរកថា',
  操作: 'ដំណើរការ​',
  查看禁运物品: 'ឆែកមើលទំនិញហាមឃាត់',
  发货类型: 'វិធីបញ្ចេញទំនិញ',
  '请选择距您最近的提货仓直发包裹，您会更快的收到包裹。':
    'សូមជ្រើសរើសខ្លាំងដែលនៅជិតលោកអ្នក,ដើម្បីទទួលបានទំនិញកាន់តែលឿន',
  选择仓库: 'ជ្រើសរើសឃ្លាំង',
  注意事项: 'ប្រុងប្រយ័ត្ន',
  我已阅读并同意: 'ខ្ញុំបានអាននិងយល់ព្រម',
  包裹转运验货规则和服务协议:
    'វិធានត្រួតពិនិត្យអធិការកិច្ចឆ្លងកាត់និងកិច្ចព្រមព្រៀងសេវាកម្ម',
  保险服务及条款: 'សេវាធានារ៉ាប់រងនិងសេវាកម្ម',
  修改: 'កែតម្រូវ',
  提交: 'ដាក់ស្នើរ',
  支付密码: 'លេខកូដប្រតិបត្តិការ',
  请输入快递单号: 'សូមបញ្ចូលលេសកូដប្រអប់',
  按城市查询: 'ឆែកតាមតំបន់',
  售后单号: 'លេខកូដប្រអប់ក្រោយពេលលក់',
  类型: 'ប្រភេទ',
  状态: 'ស្ថានភាព',
  介入原因: 'មូលហេតុចូលរួម',
  选择快递: 'ជ្រើសរើសការដឹកជញ្ជូន',
  上传凭证: 'ដាក់ភស្ដុតាង',
  关闭: 'បិទ',
  商品信息: 'អំពីទំនិញ',
  '退/换货数量': 'ចំនួនទំនិញដែលបង្វិលទៅវិញ',
  单价: 'តម្លៃរាយ',
  显示全部: 'បង្ហាញអោយឃើញទាំងអស់',
  原因: 'មូលហេតុ',
  退款金额: 'ទឹកប្រាក់បង្វិល',
  返回设置: 'ការកំណត់ត្រឡប់វិញ',
  登录密码修改成功: 'ការកែប្រែលេខកូដសម្ងាត់បានជោគជ័យ',
  支付密码修改成功: 'ការកែប្រែលេខកូដប្រតិបត្តិការបានជោគជ័យ',
  手机号修改成功: 'ការកែប្រែលេខទូរស័ព្ទបានជោគជ័យ',
  邮箱修改成功: 'ការកែប្រែប្រអប់សារបានជោគជ័យ',
  新增收货地址: 'បន្ថែមទីតាំងទទួលទំនិញ',
  有效期至: 'សុលភាពដល់ធថ្ងៃទី',
  发起配送: 'ដាក់ស្នើរដឹកជញ្ជូន',
  箱子号: 'លេខប្រអប់',
  请输入地址: 'សូមបញ្ចូលអាសយដ្ឋាន',
  流水号: 'លេខសម្គាល់បើកទំនិញ',
  运费: 'ថ្លៃដឹក',
  客户代号: 'លេខកូដKH',
  提交时间: 'ពេលវេលាដាក់ស្នើរ',
  预约取货时间: 'ប៉ានស្មានពេលវេលាបើកយកទំនិញ',
  体积: 'ទំហំ',
  催单: 'បន្ទឿនបុង',
  全选: 'ជ្រើសរើសទាំងអស់',
  支付: 'បង់ប្រាក់',
  发起: 'ដាក់ស្នើរ',
  订单号: 'លេខបញ្ជាទិញ',
  售后中: 'កំពុងដោះស្រាយ',
  取消订单: 'ការបញ្ជាទិញបានបោះបង់',
  支付订单: 'បង់ប្រាក់',
  打印标签: 'បញ្ជាក់បង្កាន់ដៃ',
  取消标签: 'លុបចោលការបញ្ជាក់',
  确认签收: 'បញ្ជាក់បានទទួលទំនិញ',
  待补款: 'រង់ចាំបង់ប្រាក់បន្ថែម',
  发起售后: 'សេវាបម្រើក្រោយពេលទិញ',
  '订单号/商品名称': 'លេខលមដាប់/ឈ្មោហផលិតផល',
  采购失败等待操作订单: 'ការបញ្ជាទិញបានបរាជ័យរង់ចាំម្ដងទៀត',
  金额: 'ចំនួនទឹកប្រាក់',
  账户余额: 'ទឹកប្រាក់នៅសល់',
  充值: 'បញ្ចូលប្រាក់',
  应付金额: 'ទឹកប្រាក់ត្រូវបង់',
  交易密码: 'លេខកូដប្រតិបត្តិការ',
  优惠劵: 'កាតបញ្ចុះតម្លៃ',
  线下充值: 'បញ្ចូលប្រាក់នៅខាងក្រៅ',
  汇入银行: 'បញ្ជូលតាមធនាគារ',
  充值金额: 'ទឹកប្រាក់បញ្ចូល',
  充值时间: 'ពេលវាលាបញ្ចូលប្រាក់',
  充值证明: 'ការបញ្ជាក់ពីការបញ្ចូលប្រាក់',
  支付宝充值: 'បញ្ចូលប្រាក់តាមAlipay',
  币种: 'កាក់',
  最小充值金额: 'ចំនួនទឹកប្រាក់អប្បបរិមា',
  最大充值金额: 'ចំនួនទឹកប្រាក់អតិបរមា',
  手续费: 'ថ្លៃសេវា',
  Wing充值: 'បញ្ចូលប្រាក់តាមWing',
  设为默认: 'ដាក់ជាលំនាំដើម',
  默认仓库: 'កំណត់ឃ្លាំង',
  售后详情: 'ព័ត៌មានលម្អិតក្រោយពេលទិញ',
  请输入姓名: 'សូមបញ្ចូលឈ្មោះ',
  手机号: 'លេខទូរស័ព្ទ',
  请输入手机号: 'សូមបញ្ចូលលេខទូរស័ព្ទ',
  所在地区: 'តំបន់ដែលនៅ',
  选择地址: 'ជ្រើសរើសអាសយដ្ឋាន',
  详细住址: 'អាសយដ្ឋានលម្អិត',
  请输入详细住址: 'សូមបញ្ចូលអាសយដ្ឋានលម្អិត',
  地址照片: 'រូបភាពអាសយដ្ឋាន',
  设为默认地址: 'កំណត់ជាលំនាំដើម',
  订单信息: 'ព័ត៌មានពីការបញ្ជាទិញ',
  充值信息: 'ព័ត៌មានពីការបញ្ចូលប្រាក់',
  提货信息: 'ព័ត៌មានពីការដកទំនិញ',
  是否删除: 'លុបចោលឬទេ',
  查询时间: 'ពេលវេលាស្វែងរក',
  清空: 'លុបចោល',
  详情: 'ព័ត៌មានលម្អិត',
  录单时间: 'ពេលវេលាបញ្ចូលបុង',
  商品数量: 'ចំនួនទំនិញ',
  提货方式: 'វិធីសាស្ដ្រដកទំនិញ',
  合并: 'ដាក់បញ្ចូលគ្នា',
  已验证: 'បានបញ្ជាក់',
  物流状态: 'ស្ថានភាពការដឹកជញ្ជូន',
  箱子内含件数: 'ចំនួនទំនិញដែលមានក្នុងប្រអប់',
  大陆出库时间: 'ពេលវេលាឃ្លាំងចិនបញ្ចេញទំនិញ',
  实重: 'ទម្ងន់',
  计费重量: 'ថ្លៃដឹកគិតតាមទម្ងន់',
  国际运费: 'ថ្លៃដឹកអន្ដរជាតិ',
  其他费用: 'សេវាផ្សេងៗ',
  总金额: 'ទឹកប្រាក់សរុប',
  用户名: 'ឈ្មោះអតិថិជន',
  昵称: 'ឈ្មោះក្រៅ',
  用户代码: 'លេខសម្គាល់KH',
  生日: 'ថ្ងៃខែឆ្នាំកមណើត',
  性别: 'ភេទ',
  男: 'បុរស',
  女: 'នារី',
  绑定业务员: 'ភ្ជាប់កូដអ្នកលក់',
  保存: 'រក្សាទុក',
  我的余额: 'ទឹកប្រាក់នៅសល់របស់ខ្ញុំ',
  提现: 'ដកប្រាក់',
  充值记录: 'កំណត់ត្រាបញ្ចូលប្រាក់',
  提现记录: 'កំណត់ត្រាដកប្រាក់',
  收支明细: 'របាយការណ៍ចំណាយចំណូល',
  时间: 'ពេលវេលា',
  充值渠道: 'វិធីបញ្ចូលប្រាក់',
  提现卡号: 'លេខកាតដកប្រាក់',
  提现金额: 'ចំនួនទឹលប្រាក់ត្រូវដក',
  交易号: 'លេខប្រតិបត្តិការ',
  收入: 'ចំណូល',
  支出: 'ចំណាយ',
  余额: 'សមតុល្យ',
  返回我的钱包: 'ត្រឡប់ទៅកាបូបរបស់ខ្ញុំ',
  余额提现: 'ដកប្រាក់នៅសល់',
  输入提现金额: 'បញ្ចូលចំនួនទឹកប្រាក់ត្រូវដក',
  输入您的交易密码: 'បញ្ចូលលេខកូដប្រតិបត្តិរបស់អ្នក',
  请选择出您提现的原因: 'សូមជ្រើសរើសមូលហេតុនៃការដកប្រាក់',
  可多选: 'អាចជ្រើសបានច្រើន',
  上传身份信息: 'ដាក់ស្នើរព័ត៌មានអត្តសញ្ញាណប័ណ្ណ',
  验证身份: 'បញ្ជាក់ការដាក់ស្នើរ',
  完成: 'រួចរាល់',
  验证码: 'លេខកូដផ្ទៀងផ្ទាត់',
  获取验证码: 'ទទួលលេខកូដផ្ទៀងផ្ទាត់',
  修改登录密码: 'កែប្រែលេខកូដសម្ងាត់',
  请输入旧密码: 'សូមបញ្ចូលលេខកូដចាស់',
  旧密码: 'លេខកូដចាស់',
  请输入新密码: 'សូមបញ្ចូលលេខកូដថ្មី',
  新密码: 'លេខកូដថ្មី',
  再次输入新密码: 'សូមបញ្ចូលលេខកូដថ្មីម្ដងទៀត',
  设置交易密码: 'កំណត់លេខកូដប្រតិបត្តិការ',
  验证方式: 'វិធីផ្ទៀងផ្ទាត់',
  设置支付密码: 'កំណត់លេខកូដប្រតិបត្តិការ',
  确认支付密码: 'បញ្ជាក់លេខកូដប្រតិបត្តិការ',
  页面维护中: 'ទំព័រកំពុងដំណើរការ',
  实力: 'សម្ថភាព',
  服务: 'សេវាកម្ម',
  '完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等':
    'ប្រព័ន្ធសេវាកម្មបន្ទាប់ពីការលក់ដ៏ល្អឥតខ្ចោះ៖ ការធានាពេលវេលាដឹក ការតាមដានគុណភាពផលិតផល ការធានាបង្វិលទំនិញជាដើម',
  优势: 'អត្ថប្រយោជន៍',
  前往首页: 'ទៅកាន់ទំព័រដើម',
  售后订单号: 'លេខលំដាប់ក្រោយពីលក់',
  售后订单: 'បុងក្រោយពេលលក់',
  发起时间: 'ពេលវេលាបង្កើត',
  快递公司: 'ក្រុមហ៊ុនដឹកជញ្ជូន',
  商品图片: 'រូបភាពទំនិញ',
  商品名称: 'ឈ្មោះទំនិញ',
  话费充值: 'បញ្ចូលកាតទូរស័ព្ទ',
  请输入以0开头的手机号码: 'សូមបញ្ជូលលេខទូរស័ព្ទដែលផ្ដើមដោយក្បាលលេខ0',
  例如: 'ឧទាហរណ៍',
  国际快递: 'ដឹកជញ្ជូនអន្ដរជាតិ',
  包裹查询: 'ស្វែងរកកញ្ចប់ទំនិញ',
  网点查询: 'ស្វែងរកគេហទំព័រ',
  如何注册: 'របៀបចុះឈ្មោះ',
  如何充值: 'របៀបបញ្ចូលប្រាក់',
  如何提现: 'របៀបដកប្រាក់',
  '找到“免费注册”': 'ស្វែងរក”ចុះឈ្មោះឥតគិតថ្លៃ”',
  填写注册信息: 'បំពេញព័ត៌មានចុះឈ្មោះ',
  '①“用户名”必填,并且唯一。':
    '①“ឈ្មោះអតិថិជន”ត្រូវតែបំពេញ,ព្រមទាំងត្រូវខុសពីគេ。',
  '② 设定登录密码,密码长度不能小于6位数。':
    '②​ បំពេញលេខកូដអាខោន,មិនអាចតិចជាង6ខ្ទង់',
  '③“邮箱”与“手机号”非必填,若填写,则必须验证。':
    '③“ប្រអប់សំបុត្រ”ឬ“លេខទូរស័ព្ទ”មិនកំណត់ត្រូវតែបំពេញ,ប្រសិនបំពេញត្រូវបញ្ជាក់មានលេខកូដផ្ទៀងផ្ទាត់',
  '④“支付密码”选填,密码长度不能小于6位数。':
    '④“លេខកូដប្រតិបត្តិការ”,មិនអាចតិចជាង6ខ្ទង់',
  '⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。':
    '⑤“អ្នកលក់”(អាចជ្រើសរើសអ្នកលក់តាមបុគ្គលិកដែលបានណែនាំអ្នកក្នុងការប្រើប្រា់ជាមួយLODA.ប្រសិនគ្មានអាចមិនបំពេញបាន)',
  勾选用户注册协议: 'ជ្រើសរើសកិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់',
  '勾选用户注册协议，点击【用户注册协议】，可查看协议内容':
    'ជ្រើសរើសកិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់,ចុចលើ【កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់】，អាចមើលជាមួយនឹងខ្លឹមសារខាងក្នុង',
  查找商品: 'ស្វែងរកទំនិញ',
  加入购物车: 'បន្ថែមក្នុងរទេះទំនិញ',
  完成支付: 'បង់ប្រាក់',
  进入充值页面: 'ចូលទៅកាន់ទំព័របញ្ចូលប្រាក់',
  '②点击“充值提现”,进入账户余额与明细页面':
    '②ចុច“ដាក់ប្រាក់ដកប្រាក់”,ចូលទៅកាន់សមតុល្យគណនីនិងព័ត៌មានលម្អិត',
  '③点击账户余额右侧“充值”,进入充值页面':
    '③ចុចខាងស្ដាំសមតុល្យទឹកប្រាក់,ចូលទៅកាន់ទំព័របញ្ចូលប្រាក់',
  充值成功: 'បញ្ចូលប្រាក់បានជោគជ័យ',
  进入提现页面: 'ចូលទៅកាន់ទំព័រដកប្រាក់',
  '①登录之后,点击位于首页上方用户名,进入订单中心':
    '①បន្ទាប់ពីចូលក្នុងគណនី,ចុចលើឈ្មោះអតិថិជនដែលមាននៅខាងស្ដាំលើទំព័រដើម',
  '③点击账户余额右侧“提现”,进入提现页面':
    '③​ចុចខាងស្ដាំសមតុល្យគណនីក្នុងការ“ដកប្រាក់”,ចូលទៅកាន់ទំព័រសម្រាប់ដកប្រាក់',
  安全设置页面: 'ទំព័រកំណត់សុវត្ថិភាព',
  '①在首页顶部点击您已登录的“用户名”':
    '①ចុច“ឈ្មោះអតិថិជន”ដែលនៅខាងលើទំព័រដើមក្នុងគណនីដែលបានចូល',
  '②安全设置页面': '②ទំព័រកំណត់សុវត្ថិភាព',
  '①点击登录密码后面的“修改密码”':
    'ចុច“កែតម្រូវលេខកូដសម្ងាត់”ដែលនៅខាងក្រោយលេខកូដសម្ងាត់',
  '②填写新的登录密码、确认登录密码，点击“修改”':
    'បំពេញលេខកូដសម្ងាត់ថ្មី,បញ្ជាក់លេខកូដសម្ងាត់,រួចចុច“កែតម្រូវ”',
  '③密码修改完成': 'លេខកូដសម្ងាត់កែតម្រូវបានសម្រេច',
  启用交易密码: 'កំណត់លេខកូដប្រតិបត្តិការ',
  '①点击交易密码后面的“立即启用”':
    'ចុច“ដាក់ដំណើរការ”ខាងក្រោយលេខកូដប្រតិបត្តិការ',
  '③密码设置完成': 'កំណត់លេខកូដប្រតិបត្តិការបានសម្រេ',
  公告: 'សេចក្ដីប្រកាស',
  店铺推荐: 'ហាងណែនាំ',
  更多: 'ច្រើនជាងនេះ',
  账号登录: 'ចូលក្នុងគណនី',
  手机登录: 'ចូលតាមទូរស័ព្ទ',
  账号: 'គណនី',
  密码: 'លេខកូដសម្ងាត់',
  记住用户: 'ចងចាំ',
  忘记密码: 'ភ្លេចពាក្យសម្ងាត់',
  '登 录': 'ចូល',
  免费注册: 'ចុះឈ្មោះដោយឥតគិតថ្លៃ',
  第三方绑定: 'ភ្ជាប់ដោយភាគីទី3',
  绑定: 'ភ្ជាប់',
  小时内: 'ក្នុងចន្លោះម៉ោង',
  加入时间: 'ពេលវេលាចូល',
  个月前: 'Xខែមុន',
  全部商品: 'ទំនិញទាំងអស់',
  下单时间: 'ពេលវេលាបញ្ជាទិញ',
  补款: 'បង់ប្រាក់បន្ថែម',
  收货人信息: 'ព័ត៌មានអ្នកទទួល',
  手机号码: 'លេខទូរស័ព្ទ',
  配送信息: 'ព័ត៌មានដឹកជញ្ជូន',
  付款信息: 'ព័ត៌មានពីការបង់ប្រាក់',
  付款方式: 'វិធីសាស្ដ្របង់ប្រាក់',
  到齐发货: 'រង់ចាំបញ្ចូលចូលគ្នា',
  货到即发: 'ដល់ភ្លាមដឹកចេញភ្លាម',
  是否取消: 'បោះបង់ឬទេ',
  取消补款: 'បោះបង់ការបង់ប្រាក់បន្ថែម',
  补货款: 'បង់ប្រាក់បន្ថែម',
  小计: 'សរុប',
  申请平台介入: 'ដាក់ស្នើរអោយវេទិកាចូលរួម',
  撤销申请: 'បោះបង់ការដាក់ស្នើរ',
  已付金额: 'ទឹកប្រាក់បានបង់',
  补款金额: 'ទឹកប្រាក់ត្រូវបង់បន្ថែម',
  正在审核: 'កំពុងពិនិត្យ',
  确认支付: 'បញ្ជាក់ការបង់ប្រាក់',
  确认收货: 'បញ្ជាក់ទទួលទំនិញ',
  合计: 'សរុប',
  留言: 'ចុចដើម្បីទុកសារ',
  支付方式: 'វិធីសាស្រ្ដទូទាត់',
  '店铺合计(含运费)': 'ហាងសរុប(មានគិតសេវាដឹក)',
  店铺合计: 'ហាងសរុប',
  查看禁运品: 'ឆែកមើលមុខទំនិញហាមឃាត់',
  '商品保险费(非人为破损100%退款)':
    'ធានារ៉ាប់រងទំនិញ(ខូចខាតដោយមិនមែនទង្វើមនុស្ស,បង្វិលប្រាក់វិញ100%)',
  商品质量保障协议: 'កិច្ចព្រមព្រៀងគុណភាពផលិតផល ',
  是否拆单: 'បែងចែកបុងឬទេ',
  应付总额: 'ទឹកប្រាក់ត្រូវបង់',
  分享: 'ចែករំលែក',
  收藏商品: 'ផលិតផលដែលចូលចិត្ត',
  找同款: 'ស្វែងរកទំនិញស្រដៀង',
  起批量: 'ចំនួនបោះដុំទាបបំផុត',
  '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!':
    'សន្យាសុវត្ថិភាពប្រតិបត្តិការរបស់អ្នក ,ក្រុមហ៊ុនបង់ជូនអ្នកលក់នៅពេលអតិថិជនទទួលបានទំនិញ!',
  配送至: 'ដឹកជញ្ជូនទៅកាន់',
  该地址不支持配送: 'តំបន់នេះមិនគាំទ្រក្នុងការដឹកជញ្ជូន',
  商品来自: 'ទំនិញមកពី',
  汇率: 'អត្រាប្ដូរ',
  库存: 'ស្ដុក',
  给客服留言: 'ផ្ញើសារទៅការសេវាកម្មបម្រើអតិថិជន',
  总额: 'ទឹកប្រាក់សរុប',
  采购清单: 'បញ្ជីទិញ',
  已售完: 'ដាច់ស្ដុក',
  更新商品数据: 'កែប្រែទិន្នន័យទំនិញ',
  即时聊天: 'ជជែកផ្ទាល់',
  进入商店: 'ចូលក្នុងហាង',
  推荐商品: 'ទំនិញណែនាំ',
  商品已下架: 'ទំនិញបានដាក់ចុះ',
  '很抱歉，没有淘到该代购商品!': 'អធ្យាស្រ័យ,រកមិនឃើញទំនិញដែលត្រូវការ',
  您可以仔细检查输入的代购链接是否正确:
    'អ្នកអាចពិនិត្យមើលលីងទំនិញត្រឹមត្រូវឬទេ',
  打开原链接: 'បើកមើលលីងដើម',
  暂时不支持该链接: 'បច្ចុប្បន្នមិនគាំទ្រលីងនេះទេ',
  设置新密码: 'កំណត់លេខកូដថ្មី',
  验证: 'ផ្ទៀងផ្ទាត់',
  请输再次输入新密码: 'សូមបញ្ចូលលេខកូដសម្ងាត់ថ្មី',
  新密码设置成功: 'បញ្ចូលលេខកូដសម្ងាត់ថ្មីបានជោគជ័យ',
  立即登录: 'ចូលភ្លាមៗ',
  登录密码: 'លេខកូដសម្ងាត់',
  请输入登录密码: 'សូមបញ្ចូលលេខកូដសម្ងាត់',
  再次输入登录密码: 'បញ្ចូលលេខកូដសម្ងាត់ម្ដងទៀត',
  请再次输入登录密码: 'សូមបញ្ចូលលេខកូដសម្ងាត់ម្ដងទៀត',
  请输入邮箱: 'សូមបញ្ចូលប្រអប់សំបុត្រ',
  请输入交易密码: 'សូមបញ្ចូលលេខកូដប្រតិបត្តិការ',
  再次输入交易密码: 'បញ្ចូលលេខកូដប្រតិបត្តិការម្ដងទៀត',
  请输再次输入交易密码: 'សូមបញ្ចូលលេខកូដប្រតិបត្តិការម្ដងទៀត',
  业务员: 'លេខសម្គាល់លក់',
  用户协议: 'កិច្ចព្រមព្រៀងអតិថិជន',
  LODA隐私权政策: 'គោលការណ៍របស់ក្រុមហ៊ុនLODA',
  ztoworld隐私权政策: 'ztoworld រក្សាការសំងាត់',
  的相关店铺: 'ហាងពាក់ព័ន្ធ',
  已下架: 'បានទម្លាក់ចុះ',
  不满足起批量: 'មិនគ្រប់ចំនួនដែលបានកំណត់ក្នុងការបោះដុំ',
  '到底了!': 'ដល់ក្រោមគេហើយ!',
  '购物车空空如也，赶紧挑选商品塞满你的购物':
    'រំទេះទំនិញទទេណាស់,ស្វែងរកទំនិញបន្ថែមផងណា',
  删除: 'លុបចោល',
  all: 'ទាំងអស់',
  清空购物车: 'លុបទំនិញទាំងអស់',
  最多提交数量: 'ចំនួនដាក់ស្នើរអតិបរមា',
  已选: 'បានជ្រើសរើស',
  件: 'pcs',
  我的专属链接: 'លីងរបស់ខ្ញុំ',
  可用余额: 'ទឹកប្រាក់អាចប្រើប្រាស់បាន',
  我的优惠卷: 'កាតបញ្ចុះតម្លៃរបស់ខ្ញុំ',
  低至: 'ទាបដូច',
  货物中心: 'មជ្ឈមណ្ឌលដឹកទំនិញ',
  资产中心: 'មជ្ឈមណ្ឌលទ្រព្យសកម្ម',
  我的优惠劵: 'គូប៉ុងរបស់ខ្ញុំ',
  设置: 'រៀបចំ',
  订单中心: 'មជ្ឈមណ្ឌលបញ្ជា',
  手机: 'ទូរស័ព្ទ​ចល័ត',
  '备注:以发往海外的时间开始计算，如有节假日，就顺延几天节假日时间。':
    'សម្គាល់：គិតចាប់ពីឃ្លាំងចិនបញ្ចេញទំនិញ,ប្រសិនមានថ្ងៃបុណ្យ,នឹងអាចមានការពន្យាការមកដល់ប៉ុន្មានថ្ងៃ',
  综合排序: 'លំដាប់ទាំងអស់',
  销量升序: 'លំដាប់ការលក់ច្រើនជាងគេ',
  销量降序: 'លំដាប់ការលក់តិចជាងគេ',
  淘宝天猫: 'Taobao Tianmao',
  新品: 'ទំនិញថ្មី',
  销量: 'លំដាប់លក់',
  全部订单: 'ការបញ្ជាទិញទាំងអស់',
  待审核: 'រង់ចាំពិនិត្យ',
  待付款: 'រង់ចាំបង់ប្រាក់',
  待订购: 'រង់ចាំបញ្ជាទិញ',
  待发货: 'រង់ចាំបញ្ចេញទំនិញ',
  待签收: 'រង់ចាំចុះហត្ថលេខាទទួល',
  货在中国仓库: 'ទំនិញនៅក្នុងឃ្លាំងចិន',
  已发往海外仓库: 'បានបញ្ចេញមកក្រៅប្រទេស',
  海外仓库已签收: 'ឃ្លាំងក្រៅប្រទេសបានទទួលទំនិញ',
  客户已收货: 'អតិថិជនបានទទួលទំនិញ',
  交易完成: 'បានបញ្ចាទិញបានបញ្ចប់',
  作废订单: 'ការបញ្ជាទិញបានបោះបង់',
  商城: 'ទំនិញក្នុងស្រុក',
  话费: 'ប្រាក់ទូរស័ព្ទ',
  等待付款: 'រង់ចាំបង់ប្រាក់',
  已经付款: 'បានបង់ប្រាក់',
  正常使用: 'ប្រើប្រាស់ធម្មតា',
  已经使用: 'បានប្រើប្រាស់',
  已经过期: 'បានផុតកំណត់',
  '登录密码能够保证您的账户安全，让您购物更放心':
    'លេខកូដសម្ងាត់អាចធានាសុវត្ថិភាពគណនីរបស់អ្នក,អ្នកអាចទិញទំនិញដោយទុកចិត្តបាន',
  '保障资金安全，提款需要设置提款密码':
    'ធានាសុវត្ថិភាពទឹកប្រាក់,ការស្នើសុំដកប្រាក់ត្រូវមានការកំណត់លេខកូដសម្ងាត់ដកប្រាក់',
  '验证后，可用于快速找回登录密码及交易密码，接收账户余额变动提醒':
    'បន្ទាប់​ពី​ការ​ផ្ទៀងផ្ទាត់ វា​អាច​ត្រូវ​បាន​ប្រើ​ដើម្បី​ស្វែងរក​ពាក្យ​សម្ងាត់ដែលបាត់និង​ពាក្យ​សម្ងាត់​ប្រតិបត្តិការមកវិញ ហើយ​ទទួល​បាន​ការ​រំលឹក​អំពី​ការ​ផ្លាស់​ប្តូរ​សមតុល្យ​គណនី។',
  '绑定邮箱后，可用于找回密码，接收相关邮件':
    'បន្ទាប់ពីចងប្រអប់សំបុត្ររួច វាអាចត្រូវបានប្រើដើម្បីស្វែងរកលេខកូដសម្ងាត់ និងទទួលអ៊ីមែលដែលពាក់ព័ន្ធ',
  验证手机: 'ផ្ទៀងផ្ទាត់លេខទូរស័ព្ទ',
  验证邮件: 'ផ្ទៀងផ្ទាត់ប្រអប់សារ',
  绑定手机号: 'ភ្ជាប់លេខទូរស័ព្ទ',
  修改手机号: 'ប្ដូរលេខទូរស័ព្ទ',
  已认证手机号: 'បានផ្ទៀងផ្ទាត់លេខទូរស័ព្ទ',
  绑定邮箱: 'ភ្ជាប់ប’រអប់សារ',
  修改邮箱: 'កែប្រែប្រអប់សារ',
  已认证邮箱: 'បានផ្ទៀងផ្ទាត់ប្រអប់សារ',
  欢迎使用LODA: 'ស្វាគមន៍មកកាន់ LODA',
  '这是柬埔寨最大电子商务平台，提供在线购物、外卖、物流快递、本地生活等生活服务，让您随时随地享受便捷生活。':
    'LODAជាវេទិកាពាណិជ្ជកម្មអេឡិចត្រូនិកដ៏ធំបំផុតនៅក្នុងប្រទេសកម្ពុជា , ផ្គត់ផ្គត់ការទិញទំនិញអនឡាញ,ដឹកជញ្ជូនម្ហូបអារហារ,ការដឹកជញ្ជូនទំនិញនិងសេវាកម្មផ្សេងៗជាច្រើន,ដែលធ្វើអោយការរស់នៅរបស់លោកអ្នកកាន់តែងាយស្រួលគ្រប់ពេលវេលា គ្រប់ទីកន្លែង។',
  '立即下载LODA APP': 'ទាញយកកម្មវិធី LODA app ឥឡូវនេះ',
  热搜词: 'ពាក្យស្វែងរកបន្តែម',
  大家都在买: 'មនុស្សទាំងអស់កំពុងទិញ',
  修改运输方式: 'កែប្រែវិធីដឹកជញ្ជូន',
  仅自己可见: 'មើលបានតែសមីខ្លួន',
  发往海外: 'ដឹកជញ្ជូនទៅក្រៅប្រទេស',
  等待收货: 'រងចាំទទួលទំនិញ',
  中中运费: 'ថ្លៃដឹកជញ្ជូននៅចិន',
  等待审核: 'រងចាំការត្រួតពិនិត្យ',
  等待采购: 'រងចាំការបញ្ជាទិញ',
  付款成功: 'ការបង់ប្រាក់ជោគជ័យ',
  商品出库: 'ផលិតផលបានចេញពីស្តុក',
  '请按照示例上传图片，图片不正确或者不清楚都会导致审核过慢或者审核不通过':
    'សូមបញ្ចូលរូបភាពតាមការបញ្ហាញខាងលើ រូបភាពមិនត្រឹមត្រូវឬមិនច្បាស់បណ្ដាលអោយប្រាក់ចូលក្នុងកាបូបលុយមានភាពយឺតយ៉ាវឬត្រូវបានបដិសេធ',
  请输入正确手机号: 'សូមបញ្ចូលលេខទូរស័ព្ទត្រឹមត្រូវ។',
  我要购物: 'បន្តក្នុងការទិញទំនិញ',
  继续充值: 'បញ្ចូលប្រាក់ម្ដងទៀត',
  'LODA柬埔寨-网上购物平台 | 批发&代购,国际转运&本地快递,外卖订餐,商城':
    'LODA កម្ពុជា - Cambodia Online Shopping Platform - Wholesale & Purchasing, International Shipping & Delivery Express, Food Delivery, Mall',
  重新下单: 'ការបញ្ជាទិញម្តងទៀត',
  '本地(柬埔寨)': '  ក្នុងស្រុក ( កម្ពុជា )',
  '海外(中国)': '  ក្រៅស្រុក ( ចិន )',
  '提交成功!您的充值号为': 'បានដាក់ស្នើដោយជោគជ័យលេខដែលអ្នកបញ្ចូលគឺ',
  大于库存: 'ធ្ងន់ដៃមានលើសពីស្តុក',
  我的箱子: 'ប្រអប់របស់ខ្ញុំ',
  我的包裹: 'វិញ្ញាបនបត្ររបស់ខ្ញុំ',
  热销榜: 'ទំនិញលក់ដាច់ជាងគេ',
  LODA快递: 'LODA ដឹកជញ្ជូនភ្លើង',
  '下载LODA APP：柬埔寨最佳在线购物平台':
    'ទាញយកកម្មវិធី - Download LODA APP: Best Online Shopping Platform in Cambodia',
  搜索商品: 'ស្វែងរកផលិតផល',
  购物车: 'រទេះសិន',
  提交订单: 'ដាក់កម្មវិធីបញ្ជាទិញ',
  新手引导: 'ការណែនាំអតិថិជនថ្មី',
  登录: 'ចូលគណនី',
  注册: 'ចុះឈ្មោះ',
  找回密码: 'ស្វែងរកពាក្យសម្ងាត់',
  帮助中心: 'មជ្ឈមណ្ឌលជំនួយ',
  关于我们: 'លក្ខខណ្ឌ​សេវាកម្ម',
  用户中心: 'មជ្ឈមណ្ឌលអ្នកប្រើប្រាស់',
  订单详情: 'លម្អិត​ការ​បញ្ជាទិញ',
  我的订单: 'ការបញ្ជាទិញរបស់ខ្ញុំ',
  收藏夹: 'ចំនូលចិត្ត',
  乐达配送: 'ដឹកជញ្ជូន',
  收货地址: 'អាសយដ្ឋានដឹកជញ្ជូន',
  提货仓库: 'ឃ្លាំងទទួលទំនិញ',
  我的钱包: 'កាបូបរបស់ខ្ញុំ',
  我的优惠券: 'គូប៉ុងរង្វាន់របស់ខ្ញុំ',
  个人资料: 'ប្រវត្តិរូបភាពរបស់ខ្ញុំ',
  安全设置: 'ការកំណត់សុវត្ថិភាព',
  邀请好友: 'ចែករំលែកមិត្តភាពជាមួយមិត្តភាព',
  消息通知: 'សេចក្តីជូនដំណឹង',
  已加入购物车: 'បាន​បន្ថែម​ទៅ​កាន់​ប័ណ្ណ​ផ្ទុក',
  '备注 : 以发往国际物流仓的时间开始计算,不包含中国采购时间,如果有节假日,就顺延几天节假日的时间':
    'ចំណាំ: រយៈពេលខាងលើនេះគឺចាប់រាប់ពីពេលបញ្ចេញទំនិញពីឃ្លាំងនៅប្រទេសចិន ដោយមិនបូកបញ្ចូលរយៈពេលទិញទំនិញ ប្រសិនបើ ចំថ្ងៃបុណ្យ និងថ្ងៃឈប់សំរាកនឹងត្រូវពន្យាពេលតាមចំនួនថ្ងៃតាមជាក់ស្តែង។',
  成功添加: 'បាន​បន្ថែម​ដោយ​ជោគជ័យ',
  'Loda是一个真实、可靠、便捷的在线购物平台。我们的服务让您真正实现网购无国界、无时差、无距离。在这里，您可以随时随地轻松网购各式各样的商品。':
    'loda គឺជាក្រុមហ៊ុនស្មោះត្រង់ដែលលោកអ្នកអាចជឿទុកចិត្តបាន រាល់ការបញ្ជាទិញ ។ រាល់ការបញ្ជាទិញ លោកអ្នកអាចទិញដោយសេរី ពីប្រទេសចិនដោយចំណាយពេលតិច និង អាចសន្សំសំចៃបាន។',
  'Loda与中国多所知名商务机构合作，提供高品质的商品和服务，行业内最低的服务费收取标准。与多家国际快递合作，运费低廉，多种寄送方式。专业的采购团队和仓储管理，完整的客户服务体系。从购买到打包到运输，环环放心，完善的客户服务体系，及时解决您的需求。':
    'loda គឺជាក្រុមហ៊ុនស្មោះត្រង់ដែលលោកអ្នកអាចជឿទុកចិត្តបាន រាល់ការបញ្ជាទិញ ។ រាល់ការបញ្ជាទិញ លោកអ្នកអាចទិញដោយសេរី ពីប្រទេសចិនដោយចំណាយពេលតិច និង អាចសន្សំសំចៃបាន។',
  '代购中国购物网站商品（loda帮您买），手机商城（掌上购物，一站式体验），高新科技的商务模式，专业电子商务人员，提供支持多平台多渠道的商品购买，24小时采购服务。':
    'ចង់ទិញទំនិញមកពីប្រទេសចិន(យើងខ្ញុំអាចជួយទិញជូនលោកអ្នកបាន) ទិញតាមរយ:ទូរសព្ទ័ដៃ ទិញតាមចិត្តដែលលោកអ្នកប្រាថ្នាចង់បានជាមួយនឹងបទពិសោធន៍ថ្មី)បច្ចេកវិជ្ជានៃរបៀបទីញដ៍ទំនើប បុគ្គលិកជំនាញក្នុងការលក់បែបelectronicដ៏ទាន់សម័យ ផ្តល់ជូននូវប្រភពទំនិញល្អៗជាច្រើន និងការបញ្ជាទិញ 24 ម៉ោង។',
  '新生儿的成长将会一路遇到许多挫折和坎坷，却总是在困惑中寻找正确的方向，在不断变化中创新和完善。 也许今天的我们并不是令人满意，但是我们会一直努力做个有态度的网购平台。 欢迎大家多多提出对我们的想法和建议，您的需求就是我们成长的动力，我们会不断完善，做个最好的自己。':
    'ក្មេងដែលទើបនឹងកើតថ្មី គឺតែងតែជួបនូវភាពលំបាក និងឧបសគ្គ ប៉ុន្តែតែងតែស្វែងរកនូវទិសដៅត្រឹមត្រូវតាមរយៈការលំបាកនោះ។ ហើយស្ថិតនៅក្នុងការប្រែប្រួលឥតឈប់ឈរនេះគឺអាចធ្វើឲ្យក្មេងនោះកែប្រែខ្លួនឯងជាមនុស្សថ្មីមួយផ្សេងទៀតដែលប្រកបដោយភាពល្អប្រសើរ បច្ចុប្បន្ននេះប្រហែលជាយើងនៅមិនទាន់ធ្វើអោយលោកអ្នកពេញចិត្តនូវអ្វីដែលយើងមាន។ប៉ុន្តែយើងនឹងបន្តការខិតខំប្រឹងប្រែងជានិច្ចដើម្បីធ្វើជាក្រុមហ៊ុនលក់ទំនិញអនឡាញដែលមានឥរិយាបទល្អប្រសើរ សូមស្វាគមន៍ចំពោះការបញ្ជេញយោបល់និងគំនិតរបស់លោកអ្នកដល់យើងខ្ញុំ អ្វីដែលជាតំរូវការរបស់លោកអ្នកគឺជាកំលាំងដែលជំរុញអោយយើងខ្ញុំរីកចំរើន ។ យើងខ្ញុំនឹងអភិវឌ្ឌតខ្លួនអោយកាន់តែល្អប្រសើរ។',
  分钟前: ' នាទីមុន',
  小时前: ' ម៉ោងមុន',
  天前: ' ថ្ងៃមុន',
  购买过: 'កម្មង់រួច',
  女装: 'សំលៀកបំពាក់មនុស្សស្រ',
  母婴: 'ម្តាយទារក',
  美妆: 'គ្រឿងសំអាង',
  居家日用: 'សំភារៈប្រើប្រាស់ប្រចាំថ្ងៃ',
  鞋品: 'ស្បែកជើង',
  文娱车品: 'គ្រឿងតុបតែងឡាន',
  数码家电: 'សំភារៈអគ្គិសនី',
  男装: 'សំលៀកបំពាក់បុរស',
  内衣: 'អាវក្នុងនារី',
  箱包: 'វ៉ាលី',
  配饰: 'គ្រឿងលំអរ',
  户外运动: 'របស់លំហាត់ប្រាណ',
  家装家纺: 'គ្រឿងតុបតែងផ្ទះ',
  '到底啦~看看其他精选内容': 'មិនមានធាតុច្រើនទៀត',
  '很抱歉，该商品为黑名单商品!': 'សូមអភ័យទោសផលិតផលគឺជាបញ្ជីខ្មៅ!',
  商品名字: 'ឈ្មោះផលិតផល',
  '您可以选择其他喜爱商品再次尝试，谢谢。':
    'អ្នកអាចជ្រើសរើសផលិតផលដែលចូលចិត្តដើម្បីសាកល្បងម្តងទៀតសូមអរគុណ។',
  收起: 'ប្រមូលផ្ត',
  取消原因: 'មូលហេតុលុបចោល',
  确认删除吗: 'ប្រាកដជាលុបមែនទេ',
  提示: 'សាររំលឹក',
  '复制成功!快去粘贴发送给您的好友吧': 'ចំលងបានសំរេច អាចផ្ញើតំណរទៅមិត្តបាន',
  密码不一致: 'លេខសំងាត់មិនត្រឹមត្រូវ',
  '已发送验证码,请注意查收': 'លេខផ្ទៀងផ្ទាត់បានផ្ញើ សូមពិនិត្យ',
  默认地址: 'កំណត់ទីតាំង',
  是否跳转到包裹列表: 'អាចរំលងទៅទំព័រកញ្ចប់ទំនិញ',
  已复制到粘贴板: 'Copyបានសំរេចក្នុងតំណភ្ជាប់នេះ',
  点击查看客服联系方式: 'ទាក់ទងសេវាអតិថិជ',
  包邮: 'Free',
  您验证的邮箱: 'កូតអ៊ីម៉ែល',
  单件: 'Single Piece',
  集包: 'ប៉េ',
  请选择业务员: 'ជ្រើសអ្នកលក់',
  '美味佳肴、超市便利': 'រសជាតិពិសេស ​ងាយស្រួលកម្មង់',
  '随时随点随到 尽在LODA APP': 'កម្មងភ្លាមបានភ្លាម សូមជ្រើសយកAPP LODA',
  'LODA FOOD': 'ឡូដា ហ្វូត',
  服务费: "Service charge",
  订购中: '',
  "已入库（中国仓）": '',
  国际运输中: '',
  "已入库（印尼仓）": '',
  待收货: '',
  售后: '',
  请选择省份: 'សូម​ជ្រើស​ខេត្ត',
  未开通提货仓: 'រក្សាទុក​ផ្ដល់​មិន​បាន​បើក',
  请选择提货仓库: 'សូម​ជ្រើស​ការ​ទុក​ស្ថានីយ',
  自营: 'បាន​ប្រតិបត្តិ​ដោយ​ខ្លួន​ឯង',
  支付成功: 'ការ​ផ្តល់​ដោយ​ជោគជ័យ',
  自动跳转登录: 'ចូល​ដោយ​ស្វ័យ​ប្រវត្តិ',
  休息: '',
  数量必须是: 'ចំនួនត្រូវតែជាពហុគុណនៃ {name}',
  快递: 'ដឹកជញ្ជូន​ឆាប់រហ័ស',
  微信充值: 'WeChat',
  '交易密码已锁定': 'ពាក្យសម្ងាត់ប្រតិបត្តិការត្រូវបានចាក់សោ សូមព្យាយាមម្តងទៀតបន្ទាប់ពី {name}',
  不能为0: 'មិនអាច 0',
  货物类型: 'ប្រភេទទំនិញ',
  请设置提货仓: 'សូមរៀបចំឃ្លាំងដឹកជញ្ជូន',
  配送费: 'តម្លៃដឹកជញ្ជូន',
}
