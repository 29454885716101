export default {
  "粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:": "Tempelkan URL produk atau nama produk situs belanja China yang perlu dibeli, contoh:",
  信息: "Informasi / Berita",
  请输入: "Silakan Masuk",
  收货人: "Penerima",
  联系电话: "Nomor Kontak",
  邮政编码: "Kode Pos",
  提示: "Petunjuk",
  确定: "Setuju",
  取消: "Batal",
  联系人: "Kontak Pribadi",
  地址: "Alamat",
  不能为空: "Wajib Isi",
  客服介入: "Penilaian Pelanggan",
  录入包裹: "Masukan Paket",
  请选择: "Pilih",
  三月内: "Dalam Waktu 3 Bulan",
  半年内: "Dalam Waktu 6 Bulan",
  一年内: "Dalam Waktu 1 Tahun",
  全部: "Semua",
  催单成功: "Pesanan Berhasil",
  已收到货: "Barang telah di terima",
  最少6位: "Minimal 6 Karakter",
  "支付完成后,请根据您支付的情况点击下面按钮": "Setelah pembayaran selesai, silakan klik tombol di bawah ini sesuai dengan Pilihan pembayaran Anda",
  支付遇到问题: "Problem dalam Pembayaran",
  支付完成: "Selesai Pembayaran",
  不能小于5或大于150个字符: "Tidak boleh kurang dari 5 atau lebih dari 150 karakter",
  已保存: "Telah disimpan / disave",
  请选择充值金额: "Jumlah akan di top-up",
  请输入用户名: "Nama Pengguna",
  请输入验证码: "Masukan Kode Verifikasi",
  加入购物车中: "Masukan ke Keranjang",
  继续: "Lanjutkan",
  购物车详情: "Detail Belanja",
  电话: "No HP",
  邮箱: "Email",
  注册成功: "Berhasil Registrasi",
  超出提交最大数量: "Melebihi batas Maksimum pengiriman",
  确认: "Konfirmasi",
  已删除: "Cancel / Hapus",
  关于乐达: "Tentang LODA",
  加入乐达: "Bergabung Dengan LODA",
  关于: "Tentang ",
  加入: "Bergabung Dengan ",
  关于我们: "Tentang Kami",
  服务协议: "Perjanjian Pelayanan",
  服务支持: "Dukungan Layanan",
  购物指南: "Panduan belanja",
  如何下单: "Cara Order",
  联系方式: "Cara Hubungi",
  站点查询: "Pencarian Situs",
  工作时间: "Jam Operasional",
  周一至周五: "Senin hingga Jumat",
  周六至周日: "Sabtu & Minggu",
  选择部门: "Pilih Bagian / Pilih Departemen",
  无查询结果: "Hasil Pencarian",
  筛选: "Penyaringan",
  价格: "Harga",
  首页: "Halaman Depan",
  "登 录": "Login",
  卖家中心: "Pusat Penjual",
  网站导航: "Tracking / Situs Navigasi",
  退出: "Keluar",
  海外购: "Barang Luar Negri",
  本地商城: "Asal Produk",
  店铺: "Toko",
  "粘贴需要代购的中国购物网站的商品网址或者商品名称、": "Tempelkan URL produk atau nama produk situs belanja China yang perlu dibeli",
  例子: "Contoh",
  搜索店铺: "Cari Toko",
  支持商家: "Dukung Pedagang",
  阿里巴巴: "Alibaba",
  淘宝: "Tao Bao",
  天猫: "Tian Mao",
  京东: "Jing Dong",
  拼多多: "Pin Duo Duo",
  所有类别: "Semua Kategori",
  商品: "Produk",
  评价: "Penilaian",
  聊天回应率: "Respon Chat",
  回应速度: "Kecepatan Respons",
  运输方式: "Metode Pengiriman",
  包裹类目: "Kategori Paket",
  长度: "Panjang",
  "(CM)": "cm",
  宽度: "Lebar",
  高度: "Tinggi",
  重量: "Berat",
  "(KG)": "kg",
  数量: "Kuantitas",
  查询: "Menyelidiki",
  运费估算: "Estimasi Biaya",
  仓库地址: "Alamat Gudang",
  包裹信息: "Info Paket",
  新增包裹: "Paket Baru",
  快递单号: "Lacak Paket",
  货物名称: "Nama Barang",
  货物数量: "Jumlah Barang",
  "保险服务(申报价值)": "Ansuransi (Nilai Barang)",
  最大货值金额: "Nilai Maksimal Barang",
  保险费: "Biaya Asuransi",
  木架加固服务: "Jasa Packing Kayu",
  备注: "Keterangan",
  操作: "Beroperasi",
  查看禁运物品: "Periksa Barang yang dilarang",
  发货类型: "Jenis Pengiriman",
  "请选择距您最近的提货仓直发包裹，您会更快的收到包裹。": "Silakan pilih gudang penjemputan terdekat untuk mengirimkan paket Anda secara langsung, dan Anda akan menerima paket Anda lebih cepat",
  选择仓库: "Pilih Gudang",
  注意事项: "yang harus di perhatikan",
  我已阅读并同意: "Saya membaca dan menyetujui",
  包裹转运验货规则和服务协议: "Periksa paket trasshipment dan perjanjian layanan",
  保险服务及条款: "Layanan ansuransi dan syaratnya",
  修改: "Revisi",
  提交: "kirim/Menyerahkan",
  支付密码: "Kode Pembayaran",
  请输入快递单号: "Masukan nomor kurir",
  按城市查询: "Cari berdasarkan kota",
  售后单号: "Nomor pesanan setelah penjualan",
  类型: "Jenis",
  状态: "Kondisi",
  介入原因: "Alasan intervensi",
  选择快递: "Pilih Ekspedisi",
  上传凭证: "Upload Sertifikat",
  关闭: "Penutup/tutup",
  商品信息: "Info produk",
  "退/换货数量": "Jumlah return/jumlah penukaran barang",
  单价: "Harga satuan",
  显示全部: "Tampilkan semua",
  原因: "Alasan",
  退款金额: "Jumlah pengembalian dana",
  返回设置: "Pengaturan kembali",
  登录密码修改成功: "Kata sandi login berhasil diubah",
  支付密码修改成功: "Kata sandi pembayaran berhasil diubah",
  手机号修改成功: "Nomor HP berhasil diubah",
  邮箱修改成功: "Email berhasil diubah",
  新增收货地址: "Alamat pengiriman baru",
  有效期至: "Berlaku hingga",
  发起配送: "Memulai pengiriman",
  箱子号: "Nomor barang",
  请输入地址: "Silakan masukkan alamat",
  流水号: "Nomor resi",
  运费: "Biaya pengiriman",
  客户代号: "Kode pelanggan",
  提交时间: "Waktu penyerahan/pengambilan",
  预约取货时间: "Perkiraan barang dibawa",
  体积: "Volume",
  催单: "Menanyakan hal resi",
  全选: "Pilih semua",
  支付: "Pembayaran",
  发起: "Dimulai dari",
  订单号: " Nomor waybill",
  售后中: "Setelah penjualan",
  取消订单: "Cancel orderan",
  支付订单: "Pembayaran order",
  打印标签: "Print label",
  取消标签: "Batalkan label",
  确认签收: "Konfirmasi penerimaan",
  待补款: "Untuk menambahkan kekurangan biayanya",
  发起售后: "Memulai layanan purna jual",
  "订单号/商品名称": "Nomor pesanan/nama produk",
  采购失败等待操作订单: "Pembelian gagal, menunggu cara mengorder",
  金额: "Total pembelanjaan",
  账户余额: "Saldo rekening",
  充值: "Top up/isi ulang",
  应付金额: "Jumlah yang harus dibayar",
  交易密码: "kata sandi transaksi",
  优惠劵: "Kupon",
  线下充值: "Top up offline",
  汇入银行: "Transfer ke bank",
  充值金额: "Jumlah isi ulang/top up",
  充值时间: "Waktu isi ulang/top up",
  充值证明: "Bukti isi ulang/top up",
  支付宝充值: "Top up di Alipay",
  币种: "Rupiah",
  最小充值金额: "Jumlah isi ulang/top up minimum",
  最大充值金额: "Jumlah isi ulang/top up maksimum",
  手续费: "Biaya penanganan/admin",
  Wing充值: "Top up wing",
  设为默认: "ditetapkan sebagai default",
  默认仓库: "Gudang bawaan",
  售后详情: "Rincian setelah penjualan",
  请输入姓名: "Silakan ketik nama",
  手机号: "Nomor HP",
  请输入手机号: "Silakan masukan no HP",
  所在地区: "Daerah",
  选择地址: "Pilih alamat",
  详细住址: "Alamat lengkap",
  请输入详细住址: "Silakan masukkan alamat lengkap",
  地址照片: "Foto alamat",
  设为默认地址: "ditetapkan sebagai alamat default",
  订单信息: "Informasi pesanan",
  充值信息: "Informasi top up",
  提货信息: "Informasi pengambilan barang",
  是否删除: "Apakah di delete",
  查询时间: "Periksa waktu/jam",
  清空: "Mengosongkan",
  详情: "Detail",
  录单时间: "Waktu perekaman pesanan",
  商品数量: "Jumlah produk",
  提货方式: "Cara pengambilan",
  合并: "Menggabungkan/gabung",
  已验证: "Diverifikasi",
  物流状态: "Status logistik",
  箱子内含件数: "Didalam box ada berapa barang",
  大陆出库时间: "Waktu pengiriman dari China",
  实重: "Berat asli/bersih",
  计费重量: "Berat yang Dikenakan Biaya",
  国际运费: "Biaya pengiriman internasional",
  其他费用: "Biaya lain-lain",
  总金额: "Total biaya",
  用户名: "Nama pengguna",
  昵称: "Nama panggilan",
  用户代码: "kode pengguna",
  生日: "Tanggal lahir",
  性别: "Jenis kelamin",
  男: "Pria",
  女: "Wanita",
  绑定业务员: "Mengikat penjual/sales",
  保存: "Menyimpan",
  我的余额: "Saldo saya",
  提现: "Menarik cash",
  充值记录: "Catatan top up saldo",
  提现记录: "Catatan penarikan cash",
  收支明细: "Rincian pemasukan dan pengeluaran",
  时间: "Waktu",
  充值渠道: "Isi ulang saluran",
  提现卡号: "Nomor kartu tunai",
  提现金额: "Jumlah penarikan",
  交易号: "Nomor transaksi",
  收入: "Penghasilan",
  支出: "Pengeluaran",
  余额: "Sisa saldo/balance",
  返回我的钱包: "Kembali ke dompet saya",
  余额提现: "Penarikan saldo",
  输入提现金额: "Masukkan jumlah penarikan",
  输入您的交易密码: "Masukkan kata sandi transaksi Anda",
  请选择出您提现的原因: "Silakan pilih alasan penarikan Anda",
  可多选: "Banyak pilihan",
  上传身份信息: "Unggah informasi identitas",
  验证身份: "Verifikasi identitas",
  完成: "Selesai",
  验证码: "Kode verifikasi",
  获取验证码: "Dapatkan kode verifikasi",
  修改登录密码: "Ubah kata sandi",
  请输入旧密码: "Silakan masukkan sandi lama",
  旧密码: "Password lama",
  请输入新密码: "Silakan masukkan kata sandi baru",
  新密码: "Kata sandi baru",
  再次输入新密码: "Masukkan kembali ulang sandi baru",
  设置交易密码: "Tetapkan kata sandi transaksi",
  验证方式: "Cara mengidentifikasi",
  设置支付密码: "Siapkan kata sandi pembayaran",
  确认支付密码: "Konfirmasi kata sandi pembayaran",
  页面维护中: "Halaman ini sedang dalam pemeliharaan",
  实力: "Kekuatan",
  服务: "Melayani",
  "完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等": "Sistem layanan setelah penjualan yang lengkap: jaminan ketepatan waktu, pelacakan kualitas produk, jaminan pengembalian dan penukaran, dll.",
  优势: "Keuntungan/kelebihan",
  前往首页: "Buka halaman beranda",
  售后订单号: "Nomor pesanan",
  售后订单: "Pesanan purna jual",
  发起时间: "waktu mulai",
  快递公司: "Perusahaan kurir/ekspedisi",
  商品图片: "Gambar produk",
  商品名称: "Nama produk",
  话费充值: "Isi ulang pulsa telepon",
  请输入以0开头的手机号码: "Silakan masukkan nomor ponsel Anda dimulai dengan 0",
  例如: "Misalnya/contohnya",
  国际快递: "Ekspedisi Internasional",
  包裹查询: "Keterangan paket",
  网点查询: "Pencarian website",
  如何注册: "Bagaimana cara daftar:",
  如何充值: "Cara mengisi ulang",
  如何提现: "Cara menarik uang tunai",
  "找到“免费注册”": 'Temukan "Pendaftaran Gratis"',
  填写注册信息: "Isi informasi pendaftaran",
  "①“用户名”必填,并且唯一。": '①"Nama Pengguna" wajib diisi dan unik',
  "② 设定登录密码,密码长度不能小于6位数。": "② Tetapkan kata sandi login. kata sandi tidak boleh kurang dari 6 digit",
  "③“邮箱”与“手机号”非必填,若填写,则必须验证。": '③"Email" dan "Nomor ponsel" tidak wajib diisi, jika diisi harus diverifikasi',
  "④“支付密码”选填,密码长度不能小于6位数。": '④"Kata Sandi Pembayaran" bersifat opsional, kata sandi tidak boleh kurang dari 6 digit',
  "⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。": '⑤"Sales" (opsional, rekomendasikan nomor karyawan bisnis Anda, jika tidak, jangan diisi)',
  勾选用户注册协议: "Periksa Perjanjian Pendaftaran Pengguna",
  "勾选用户注册协议，点击【用户注册协议】，可查看协议内容": "Periksa Perjanjian Pendaftaran Pengguna dan klik [Perjanjian Pendaftaran Pengguna] untuk melihat isi perjanjian.",
  查找商品: "Temukan produk",
  加入购物车: "Tambahkan ke troli",
  完成支付: "Selesaikan pembayaran",
  进入充值页面: "Masuk ke halaman isi ulang",
  "②点击“充值提现”,进入账户余额与明细页面": '②Klik "Setor dan Tarik" untuk masuk ke saldo akun dan halaman detail',
  "③点击账户余额右侧“充值”,进入充值页面": '③Klik "Isi Ulang" di sisi kanan saldo akun untuk masuk ke halaman isi ulang',
  充值成功: "Isi ulang berhasil",
  进入提现页面: "Masuk ke halaman penarikan",
  '①登录之后,点击位于首页上方用户名,进入订单中心': "①Setelah login, klik nama pengguna di bagian atas halaman beranda untuk masuk ke pusat pemesanan",
  '③点击账户余额右侧“提现”,进入提现页面': '③Klik "Tarik" di sisi kanan saldo akun untuk masuk ke halaman penarikan',
  安全设置页面: "Halaman pengaturan keamanan",
  '①在首页顶部点击您已登录的“用户名”': '①Klik "Nama Pengguna" Anda yang sudah login di bagian atas halaman beranda',
  '②安全设置页面': "②Halaman pengaturan keamanan",
  '①点击登录密码后面的“修改密码”': '①Klik "Ubah Kata Sandi" di belakang kata sandi login',
  '②填写新的登录密码、确认登录密码，点击“修改”': '② Isi kata sandi login baru, konfirmasi kata sandi login, dan klik "Ubah"',
  '③密码修改完成': "③Modifikasi kata sandi selesai'",
  启用交易密码: "Aktifkan kata sandi transaksi",
  '①点击交易密码后面的“立即启用”': '①Klik "Aktifkan Sekarang" di sebelah kata sandi transaksi',
  '③密码设置完成': "③Pengaturan kata sandi selesai",
  公告: "Pengumuman",
  店铺推荐: "Rekomendasi toko",
  更多: "Lagi",
  账号登录: "Akun Masuk",
  手机登录: "Masuk dengan telepon",
  账号: "Akun",
  密码: "kata sandi",
  记住用户: "Ingat pengguna",
  忘记密码: "Lupa kata sandi",
  免费注册: "pendaftaran gratis",
  第三方绑定: "Mengikat pihak ketiga",
  绑定: "Mengikat",
  小时内: "dalam beberapa jam",
  加入时间: "Waktu bergabung",
  个月前: "Bulan lalu",
  全部商品: "Semua produk",
  下单时间: "Waktu pemesanan",
  补款: "Penambahan",
  收货人信息: "Informasi penerima",
  手机号码: "Nomor HP",
  配送信息: "Informasi pengiriman",
  付款信息: "Informasi pembayaran",
  付款方式: "Cara Pembayaran",
  到齐发货: "Siap untuk diantar",
  货到即发: "Barang tiba segera dikirim",
  是否取消: "Apakah batal",
  取消补款: "Cancel pemabayaran",
  补货款: "Pembayaran kekurangannya",
  小计: "Subtotal",
  申请平台介入: "Ajukan permohonan intervensi platform",
  撤销申请: "Aplikasi dibatalkan",
  已付金额: "Jumlah yang dibayarkan",
  补款金额: "Jumlah pembayaran kekurangannya",
  正在审核: "Sedang ditinjau",
  确认支付: "Konfirmasi pembayaran",
  确认收货: "Konfirmasi penerimaan barang",
  合计: "Jumlah",
  留言: "Pesan",
  支付方式: "Cara Pembayaran",
  '店铺合计(含运费)': "Total toko (termasuk biaya pengiriman)",
  店铺合计: "Jumlah toko",
  查看禁运品: "Lihat item dilarang",
  '商品保险费(非人为破损100%退款)': "Premi asuransi komoditas (Pengembalian dana 100% untuk kerusakan non-buatan)",
  商品质量保障协议: "Perjanjian jaminan kualitas produk",
  是否拆单: "Apakah akan dibagi orderan pesanannya",
  应付总额: "Jumlah total yang harus dibayar",
  分享: "Berbagi",
  收藏商品: "Produk Favorit",
  找同款: "Temukan produk yang sama",
  起批量: "Ukuran kumpulan minimum",
  '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!': "Kami menjamin keamanan transaksi Anda: kami hanya akan mencairkan dana kepada penjual setelah pembeli menerima barang!",
  配送至: "Kirim ke",
  该地址不支持配送: "Alamat ini tidak mendukung pengiriman",
  商品来自: "Produk dari",
  汇率: "Nilai tukar",
  库存: "Persediaan",
  给客服留言: "Tinggalkan pesan ke layanan pelanggan",
  总额: "jumlah keseluruhan:",
  采购清单: "Daftar Pembelian",
  已售完: "Terjual habis",
  更新商品数据: "Perbarui data produk",
  即时聊天: "Chat langsung",
  进入商店: "Masuk ke toko",
  推荐商品: "Produk yang direkomendasikan",
  商品已下架: "Produk tidak dijual lagi:",
  '很抱歉，没有淘到该代购商品!': "Maaf, produk pembelian tidak ditemukan!'",
  您可以仔细检查输入的代购链接是否正确: "Anda dapat periksa ulang apakah link pembelian yang diinput sudah benar",
  打开原链接: "Buka tautan asli",
  暂时不支持该链接: "Tautan ini tidak didukung saat ini",
  设置新密码: "Tetapkan kata sandi baru",
  验证: "Kode verifikasi",
  请再次输入新密码: "Silakan masukkan kata sandi baru lagi",
  新密码设置成功: "Kata sandi baru berhasil dibuat",
  立即登录: "Segera masuk",
  登录密码: "Kata sandi masuk",
  请输入登录密码: "Silakan masukkan kata sandi",
  再次输入登录密码: "Coba kembali kata sandi login",
  请再次输入登录密码: "Silakan masukkan kembali kata sandi login",
  请输入邮箱: "Silakan masukkan email",
  请输入交易密码: "Silakan masukkan sandi transaksi",
  再次输入交易密码: "Masukkan kembali password transaksi",
  请输再次输入交易密码: "Silakan masukkan kembali sandi transaksi",
  业务员: "Pramuniaga",
  用户协议: "Perjanjian Pengguna",
  LODA隐私权政策: "Kebijakan Privasi LODA",
  ztoworld隐私权政策: "Kebijakan Privasi ztoworld",
  的相关店铺: "Toko terkait",
  已下架: "DIhapus/telah tidak ada barangnya",
  不满足起批量: "Tidak memenuhi ukuran batch minimum",
  '到底了!': "Ini sudah berakhir!",
  '购物车空空如也，赶紧挑选商品塞满你的购物': "'Keranjang belanja kosong, segera isi belanjaanmu dengan item'",
  删除: "Delete",
  all: "Semua",
  清空购物车: "Hapus keranjang belanja",
  最多提交数量: "Jumlah maksimum pengiriman",
  已选: "Terpilih",
  件: "Bagian",
  我的专属链接: "Tautan eksklusif saya",
  可用余额: "Saldo Tersedia",
  我的优惠卷: "Kupon saya",
  热销榜: "Daftar buku terlaris",
  低至: "Dari",
  货物中心: "Pusat kargo",
  资产中心: "Pusat Aset",
  我的优惠劵: "Kupon saya",
  设置: "Set up:",
  订单中心: "Pusat Pemesanan",
  手机: "HP",
  '备注:以发往海外的时间开始计算，如有节假日，就顺延几天节假日时间。': "'Keterangan : Perhitungan dimulai dari waktu pengiriman ke luar negeri, apabila ada hari libur maka waktu hari libur tersebut akan diundur beberapa hari. '",
  综合排序: "Penyortiran komprehensif",
  销量升序: "Volume penjualan naik",
  销量降序: "Volume penjualan menurun",
  淘宝天猫: "Taobao dian mao",
  新品: "Produk baru",
  销量: "Volume penjualan",
  全部订单: "Orderan semua",
  待审核: "Tinjauan tertunda",
  待付款: "Pembayaran tertunda",
  待订购: "Untuk memesan",
  待发货: "Untuk dikirimkan",
  待签收: "Menunggu tanda tangan",
  货在中国仓库: "Barang di gudang China",
  已发往海外仓库: "Dikirim ke gudang luar negeri",
  海外仓库已签收: "Gudang luar negeri telah menandatangani tanda terima:",
  客户已收货: "Pelanggan telah menerima barang",
  交易完成: "Transaksi selesai",
  作废订单: "Pesanan dibatalkan",
  商城: "Mall",
  话费: "Biaya komunikasi",
  等待付款: "Menunggu pembayaaran",
  已经付款: "Sudah dibayar",
  正常使用: "Penggunaan normal",
  已经使用: "Sudah digunakan",
  已经过期: "Sudah kadaluwarsa",
  '登录密码能够保证您的账户安全，让您购物更放心': "'Sandi login dapat menjamin keamanan akun anda dan membuat belanja lebih aman'",
  '保障资金安全，提款需要设置提款密码': "'Untuk memastikan keamanan dana, perlu mengatur kata sandi penarikan untuk penarikan'",
  '验证后，可用于快速找回登录密码及交易密码，接收账户余额变动提醒': "'Setelah verifikasi, dapat digunakan untuk mengambil kata sandi login dan kata sandi transaksi dengan cepat, dan menerima pengingat perubahan saldo akun'",
  '绑定邮箱后，可用于找回密码，接收相关邮件': "'Setelah mengikat alamat email, itu dapat digunakan untuk mengambil kata sandi dan menerima email terkait'",
  验证手机: "Verifikasi ponsel",
  验证邮件: "Verifikasi email",
  绑定手机号: "Mengikat nomor ponsel",
  修改手机号: "Ganti nomor ponsel",
  已认证手机号: "Nomor ponsel terverifikasi",
  绑定邮箱: "Mengikat email",
  修改邮箱: "mengubah email",
  已认证邮箱: "Email terverifikasi",
  欢迎使用LODA: "Selamat datang pengguna LODA",
  '这是柬埔寨最大电子商务平台，提供在线购物、外卖、物流快递、本地生活等生活服务，让您随时随地享受便捷生活。':
    "Ini adalah platform e-commerce terbesar di Kamboja, menyediakan belanja online, bawa pulang, logistik dan pengiriman ekspres, kehidupan lokal, dan layanan kehidupan lainnya, memungkinkan anda menikmati kehidupan yang nyaman kapan saja dan di mana saja.",
  '立即下载LODA APP': "'Segera unduh APLIKASI LODA'",
  热搜词: "Pencarian populer",
  大家都在买: "Everyone's Buying",
  修改运输方式: "Ubah metode pengiriman",
  仅自己可见: "Hanya terlihat oleh sendiri",
  发往海外: "Kirim ke luar negeri",
  等待收货: "Menunggu tanda terima",
  中中运费: "Pengiriman Tiongkok-Tiongkok",
  等待审核: "Dimoderasi",
  等待采购: "Menunggu pembelian",
  付款成功: "Pembayaran berhasil",
  商品出库: "Produk dikirim dari gudang",
  '请按照示例上传图片，图片不正确或者不清楚都会导致审核过慢或者审核不通过': "'Harap unggah gambar sesuai contoh. Gambar yang salah/tidak jelas akan menyebabkan peninjauan menjadi lambat atau gagal.'",
  请输入正确手机号: "",
  我要购物: "Silakan masukkan nomor HP yang benar",
  继续充值: "Lanjutkan isi ulang/top up",
  'LODA柬埔寨-网上购物平台 | 批发&代购,国际转运&本地快递,外卖订餐,商城': "'LODA Kamboja-Platform Belanja Online | Grosir & Pembelian, Transshipment Internasional & Ekspres Lokal, Pemesanan food, Mall'",
  重新下单: "Pesan ulang",
  '本地(柬埔寨)': "Lokal (Kamboja)",
  '海外(中国)': "Luar Negeri (Tiongkok)",
  '提交成功!您的充值号为': "'Pengajuan berhasil! Nomor isi ulang anda adalah",
  大于库存: "Lebih besar dari stok",
  我的箱子: "Kotak saya/pembelanjaan saya",
  我的包裹: "Paket saya",
  LODA快递: "Express LODA",
  '下载LODA APP：柬埔寨最佳在线购物平台': "Unduh LODA APP: Platform belanja online terbaik di Kamboja",
  搜索商品: "Telusuri produk",
  购物车: "Keranjang/troli",
  提交订单: "Masukan pesanan",
  新手引导: "Panduan Pemula",
  登录: "Login",
  注册: "Daftar",
  找回密码: "Ambil kata sandi ulang",
  帮助中心: "Pusat Bantuan",
  用户中心: "Bantuan customer",
  订单详情: "Detail pesanan",
  我的订单: "Pesananku",
  收藏夹: "Favorit/paling disukai",
  乐达配送: "Pengantaran LODA",
  收货地址: "Alamat pengiriman",
  提货仓库: "Gudang pengambilan",
  我的钱包: "Dompet saya",
  我的优惠券: "Kupon diskon saya",
  个人资料: "Informasi pribadi",
  安全设置: "Pengaturan keamanan",
  邀请好友: "Mengundang teman",
  消息通知: "Pemberitahuan",
  已加入购物车: "Ditambahkan ke troli",
  '备注 : 以发往国际物流仓的时间开始计算,不包含中国采购时间,如果有节假日,就顺延几天节假日的时间':
    "'Keterangan: Perhitungan dimulai dari waktu pengiriman ke gudang logistik internasional, tidak termasuk waktu pengadaan di China. Jika ada hari libur maka akan ditunda beberapa hari.'",
  成功添加: "Berhasil ditambahkan",
  'Loda是一个真实、可靠、便捷的在线购物平台。我们的服务让您真正实现网购无国界、无时差、无距离。在这里，您可以随时随地轻松网购各式各样的商品。':
    "Loda adalah platform belanja online yang autentik, andal, dan nyaman. Layanan kami memungkinkan anda untuk benar-benar mewujudkan belanja online tanpa batas, jeda waktu dan jarak. Di sini, dapat dengan mudah berbelanja berbagai produk secara online kapan saja, di mana saja.",
  'Loda与中国多所知名商务机构合作，提供高品质的商品和服务，行业内最低的服务费收取标准。与多家国际快递合作，运费低廉，多种寄送方式。专业的采购团队和仓储管理，完整的客户服务体系。从购买到打包到运输，环环放心，完善的客户服务体系，及时解决您的需求。':
    "Loda bekerja sama dengan banyak institusi bisnis ternama di Tiongkok untuk menyediakan barang dan jasa berkualitas tinggi, dengan standar pembebanan biaya layanan terendah di industri. Bekerja sama dengan banyak perusahaan pengiriman ekspres internasional, biaya pengiriman rendah dan berbagai metode pengiriman tersedia. Tim pembelian profesional dan manajemen pergudangan, sistem layanan pelanggan lengkap. Dari pembelian hingga pengemasan hingga transportasi, kami memberi Anda ketenangan pikiran dan sistem layanan pelanggan yang lengkap untuk menyelesaikan kebutuhan Anda secara tepat waktu.",
  '代购中国购物网站商品（loda帮您买），手机商城（掌上购物，一站式体验），高新科技的商务模式，专业电子商务人员，提供支持多平台多渠道的商品购买，24小时采购服务。':
    'Membeli produk dari situs Tiongkok (LODA membantu anda membelinya), mal seluler (belanja perangkat genggam, pengalaman terpadu), model bisnis berteknologi tinggi, personel e-niaga profesional, memberikan dukungan untuk produk multi-platform dan multi-saluran pembelian, layanan pengadaan 24 jam.',
  '新生儿的成长将会一路遇到许多挫折和坎坷，却总是在困惑中寻找正确的方向，在不断变化中创新和完善。 也许今天的我们并不是令人满意，但是我们会一直努力做个有态度的网购平台。 欢迎大家多多提出对我们的想法和建议，您的需求就是我们成长的动力，我们会不断完善，做个最好的自己。':
    "Bayi baru lahir akan menghadapi banyak kemunduran dan pasang surut dalam perjalanannya, namun mereka selalu mencari arah yang benar dalam kebingungan, berinovasi dan berkembang di tengah perubahan yang terus-menerus.Mungkin saat ini kami kurang memuaskan, namun kami akan selalu berusaha menjadi platform belanja online yang berakhlak.Kami menyambut semua orang untuk mengemukakan ide dan saran Anda untuk kami. Kebutuhan anda adalah pendorong pertumbuhan kami. Kami akan terus berkembang dan menjadi versi terbaik",
  分钟前: "Beberapa menit yang lalu",
  小时前: "Satu jam yang lalu",
  天前: "Beberapa hari yang lalu",
  购买过: "Pernah belanja",
  女装: "Pakaian Wanita",
  母婴: "Ibu dan bayi",
  美妆: "Kosmetik",
  居家日用: "Peralatan sehari-hari di rumah",
  鞋品: "Produk sepatu",
  文娱车品: "Produk otomotif",
  数码家电: "Produk electrik rumah",
  男装: "Pakaian Pria",
  内衣: "Pakaian dalam",
  箱包: "Tas/dompet",
  配饰: "Aksesoris",
  户外运动: "Olah raga luar rumah",
  家装家纺: "Dekorasi rumah dan tekstil rumah",
  '到底啦~看看其他精选内容': "Itu saja~ Lihat konten unggulan lainnya",
  '很抱歉，该商品为黑名单商品!': "Maaf, produk ini adalah yang masuk daftar hitam",
  商品名字: "Nama Produk",
  '您可以选择其他喜爱商品再次尝试，谢谢。': "Anda dapat memilih produk favorit lainnya untuk dicoba kembali, terima kasih",
  收起: "Keep/simpan",
  取消原因: "Alasan cancel",
  确认删除吗: "Konfirmasi penghapusan",
  '复制成功!快去粘贴发送给您的好友吧': "Berhasil disalin! copy paste dan kirimkan ke teman Anda",
  密码不一致: "Kata sandi tidak sesuai",
  '已发送验证码,请注意查收': "Kode verifikasi sudah terkirim, silakan dicek",
  默认地址: "Alamat bawaan",
  是否跳转到包裹列表: "Apakah akan melompat ke daftar paket",
  已复制到粘贴板: "Disalin ke papan klip",
  点击查看客服联系方式: "Klik untuk melihat informasi kontak layanan pelanggan",
  包邮: "Bebas biaya kirim",
  您验证的邮箱: "Email anda terverifikasi",
  单件: "Paket satuan",
  集包: "Paket koleksi",
  请选择业务员: "Silakan pilih sales penjualan",
  '美味佳肴、超市便利': "Makanan lezat, Supermarket",
  '随时随点随到 尽在LODA APP': "Akses kapan saja, di mana saja dengan aplikasi LODA",
  服务费: "Service charge",
  订购中: 'Perintah',
  "已入库（中国仓）": 'Diterima (gudang Cina)',
  国际运输中: 'Dalam transportasi internasional',
  "已入库（印尼仓）": 'Diterima (gudang Indonesia)',
  待收货: 'Untuk diterima',
  售后: 'Setelah penjualan',
  请选择省份: 'Silakan pilih provinsi',
  未开通提货仓: 'Gudang pengiriman tidak terbuka',
  请选择提货仓库: 'Silakan pilih gudang pickup',
  自营: 'Beroperasi sendiri',
  支付成功: 'Pembayaran berhasil',
  自动跳转登录: 'Masukkan ulang direksi otomatis',
  休息: 'Libur',
  数量必须是: 'Jumlahnya harus berkelipatan {name}',
  快递: 'Pengiriman cepat',
  '新用户进入首页后，点击首页右上角免费注册按钮，即可进入免费注册页面': 'Pengguna baru masuk ke halaman beranda, klik tombol di pojok kanan atas beranda untuk masuk ke halaman registrasi gratis.',
  '注意:邮箱和手机号均未验证时,只能登录不能下单;两者只需一个验证通过,则即可登录也能下单;验证邮箱或手机号可进入安全设置模块进行设置。': 'Catatan: alamat email atau nomor HP tidak diverifikasi, anda hanya dapat masuk tidak dapat melakukan pemesanan; selama salah satu dari dua verifikasi lolos, anda dapat masuk dan memesan, dapat memasukkan pengaturan keamanan; modul untuk diatur setelah memverifikasi alamat email atau nomor HP.',
  '注册完后自动登录,用户可点击浏览首页按钮,也可进入用户信息页面绑定相关信息。': 'Masuk otomatis setelah daftar. Pengguna dapat mengklik tombol jelajahi beranda atau masuk ke halaman informasi pengguna untuk mengikat informasi yang relevan.',
  '在中国电商网站（淘宝,天猫等）挑选您满意的商品，复制商品详情页链接': 'Pilih produk yang anda sukai di situs web e-commerce Tiongkok (Taobao, Tmall, dll.) dan salin tautan ke halaman detail produk',
  '将商品链接粘贴至代购搜索栏中,点击“我要代购”,手动选择颜色、尺寸等,点击“添加到购物车”': 'Tempelkan tautan produk ke dalam bilah pencarian pembelian, klik "Saya ingin membeli", pilih warna, ukuran, dll. secara manual, dan klik "Tambahkan ke Keranjang"',
  '①点击购物车,选择网购的商品,点击“提交订单”（建议将想要购买的商品全部添加至购物车后,一起提交）': '① Klik keranjang belanja, pilih item untuk belanja online, dan klik "Kirim Pesanan" (disarankan untuk menambahkan semua item yang ingin anda beli ke keranjang belanja dan mengirimkannya bersama-sama)',
  '②创建订单成功后,订单状态为“审核中”,一般在1-3个工作小时内完成审核': '②Setelah pesanan berhasil dibuat, status pesanan "dalam peninjauan", dan peninjauan biasanya selesai dalam 1-3 jam kerja.',
  '①审核通过后,点击“所有订单”中的“待付款”,进入订单页面,点击“去支付”,按照提示完成支付': '①Setelah lolos peninjauan, klik "Pembayaran Tertunda" di "Semua Pesanan" untuk masuk ke halaman pesanan, klik "Mulai Bayar" dan ikuti petunjuk untuk menyelesaikan pembayaran.',
  '②输入交易密码即可完成支付。如果未设置交易密码,请您先设置交易密码,提高您的账户的安全': '②Masukkan kata sandi transaksi untuk menyelesaikan pembayaran. Jika anda belum mengatur kata sandi transaksi, silakan atur kata sandi transaksi terlebih dahulu untuk meningkatkan keamanan akun anda',
  '③支付成功后,订单状态显示为“待收货”时,即可在当地自提点收取商品': '③Setelah pembayaran berhasil, ketika status pesanan ditampilkan sebagai "Menunggu tanda terima", Anda dapat mengambil barang di titik penjemputan setempat',
  '网站支付电子钱包付款方式,您需要先通过银行转账将订单金额汇入官方指定帐号,并在网站充值界面填写充值信息,我们的工作人员会尽快处理': 'Metode e-wallet pembayaran situs web, anda harus terlebih dahulu mentransfer jumlah pesanan ke rekening resmi yang ditunjuk melalui transfer bank, dan mengisi informasi isi ulang di antarmuka isi ulang situs web, staf kami akan memprosesnya sesegera mungkin',
  '①在中国电商网站（淘宝,天猫等）挑选您满意的商品,复制商品详情页链接': '① Pilih produk yang Anda sukai di situs web e-commerce Tiongkok (Taobao, Tmall, dll.) dan salin tautan ke halaman detail produk',
  '使用银行线下汇款的方式充值成功后,在充值页面填写“汇入银行”（网站官方指定帐号 ）、“充值金额”“充值时间”（时间需与银行汇款单上填写一致）、“充值证明”以及“备注”等信息,并点击确定完成充值': 'Setelah berhasil mengisi ulang menggunakan offline bank remittance, isi "Remittance Bank" (nomor rekening resmi yang ditunjuk di website), "Recharge Amount", "Recharge Time" (waktu harus sesuai dengan yang ada di formulir pengiriman bank), dan "Bukti Isi Ulang" di halaman isi ulang. ” dan “Keterangan” dan informasi lainnya, lalu klik OK untuk menyelesaikan isi ulang.',
  '充值完成后,需等待工作人员进行审核,审核完成之后您可以前往充值提现界面查看充值提现记录': ' Setelah pengisian ulang selesai, anda harus menunggu staf meninjaunya. Setelah peninjauan selesai, dapat membuka antarmuka isi ulang dan penarikan untuk melihat catatan isi ulang dan penarikan.',
  '①如果您还未添加银行卡,请先添加银行卡。添加前请仔细阅读《用户服务协议》,如无异议，请勾选“我已阅读《用户服务协议》”并点击确认添加。': '①Jika anda belum menambahkan kartu bank, harap tambahkan kartu bank terlebih dahulu. Harap baca "Perjanjian Layanan Pengguna" dengan cermat sebelum menambahkan. Jika tidak keberatan, harap centang "Saya telah membaca Perjanjian Layanan Pengguna" dan klik untuk mengonfirmasi penambahan.',
  '②如果已添加银行卡,请在提现页面填写“提现金额”、“提现密码”（请在“安全设置”中设置您的交易密码以便提现）,并点击确认完成提现。': '② Jika telah menambahkan kartu bank, silakan isi "Jumlah Penarikan" dan "Kata Sandi Penarikan" di halaman penarikan (harap atur kata sandi transaksi anda di "Pengaturan Keamanan" untuk penarikan), dan klik Konfirmasi untuk menyelesaikan penarikan.',
  '提现完成后,需等待工作人员进行审核,审核完成之后您可以前往充值提现界面查看充值提现记录。': 'Setelah penarikan selesai, anda harus menunggu staf meninjaunya. Setelah peninjauan selesai, dapat membuka antarmuka isi ulang dan penarikan untuk melihat catatan isi ulang dan penarikan.',
  '②点击获取验证码、填写验证码,然后填写设置提现密码、确认提现密码,点击“提交”': '②Klik untuk mendapatkan kode verifikasi, isi kode verifikasi, lalu isi kata sandi penarikan yang ditetapkan, konfirmasi kata sandi penarikan, dan klik "Kirim"',
  微信充值: 'WeChat',
  '交易密码已锁定': 'Kata sandi transaksi telah dikunci, silakan coba lagi setelah {name}',
  不能为0: 'tidak bisa 0',
  货物类型: 'Jenis kargo',
  电子钱包: 'E-Wallet',
  线下支付: 'Bayar Tunai di Mitra',
  银行转账: 'Transfer Bank',
  网上银行支付: 'Internet Banking',
  请设置提货仓: 'Silakan siapkan gudang pengiriman',
  配送费: 'Biaya Pengiriman',
}
