export default {
  "粘贴需要代购的中国购物网站的商品网址或者商品名称,例子:": 'Pegar la URL o el nombre del producto de la tienda de compras en línea de China que necesita un servicio de compra personalizado, ejemplo:',
  "信息": 'Información',
  "请输入": 'Por favor, ingrese',
  "收货人": 'Destinatario',
  "联系电话": 'Teléfono de contacto',
  "邮政编码": 'Código postal',
  "提示": 'Consejo',
  "确定": 'Aceptar',
  "取消": 'Cancelar',
  "联系人": 'Persona de contacto',
  "地址": 'Dirección',
  "不能为空": 'No puede estar vacío',
  "客服介入": 'Intervención del servicio al cliente',
  "录入包裹": 'Ingresar envío',
  "请选择": 'Seleccione',
  "三月内": 'En los últimos tres meses',
  "半年内": 'En los últimos seis meses',
  "一年内": 'En el último año',
  "全部": 'Todos',
  "催单成功": 'Recordatorio exitoso',
  "已收到货": 'Mercancía recibida',
  "最少6位": 'Mínimo de 6 caracteres',
  "支付完成后,请根据您支付的情况点击下面按钮": 'Después de completar el pago, haga clic en el botón a continuación según su situación de pago',
  "支付遇到问题": 'Problemas con el pago',
  "支付完成": 'Pago completado',
  "不能小于5或大于150个字符": 'No puede ser menor de 5 ni mayor de 150 caracteres',
  "已保存": 'Guardado',
  "请选择充值金额": 'Seleccione el monto de recarga',
  "请输入用户名": 'Ingrese su nombre de usuario',
  "请输入验证码": 'Ingrese el código de verificación',
  "加入购物车中": 'Añadido al carrito de compras',
  "继续": 'Continuar',
  "购物车详情": 'Detalles del carrito de compras',
  "电话": 'Teléfono',
  "邮箱": 'Correo electrónico',
  "注册成功": 'Registro exitoso',
  "超出提交最大数量": 'Supera la',
  "确认": 'Confirmar',
  "已删除": 'Eliminado',
  "关于乐达": 'Acerca de Leda',
  "加入乐达": 'Unirse a Leda',
  "关于": 'Acerca de ',
  "加入": 'Unirse a ',
  "关于我们": 'Acerca de nosotros',
  "服务协议": 'Acuerdo de servicio',
  "服务支持": 'Soporte de servicio',
  "购物指南": 'Guía de compras',
  "如何下单": 'Cómo hacer un pedido',
  "联系方式": 'Información de contacto',
  "站点查询": 'Búsqueda de sitio',
  "工作时间": 'Horario laboral',
  "周一至周五": 'De lunes a viernes',
  "周六至周日": 'Sábado y domingo',
  "选择部门": 'Seleccionar departamento',
  "无查询结果": 'Sin resultados de búsqueda',
  "筛选": 'Filtrar',
  "价格": 'Precio',
  "首页": 'Inicio',
  "登 录": 'Iniciar sesión',
  "卖家中心": 'Centro de vendedores',
  "网站导航": 'Navegación del sitio',
  "退出": 'Salir',
  "海外购": 'Compras en el extranjero',
  "本地商城": 'Tienda local',
  "店铺": 'Tienda',
  "粘贴需要代购的中国购物网站的商品网址或者商品名称、": 'Pegar la URL o el nombre del producto de la tienda de compras en línea de China que necesita un servicio de compra personalizado,',
  "例子": 'ejemplo',
  "搜索店铺": 'Buscar tienda',
  "支持商家": 'Tiendas compatibles',
  "阿里巴巴": 'Alibaba',
  "淘宝": 'Taobao',
  "天猫": 'Tmall',
  "京东": 'JD.com',
  "拼多多": 'Pinduoduo',
  "所有类别": 'Todas las categorías',
  "商品": 'Productos',
  "评价": 'Reseñas',
  "聊天回应率": 'Tasa de respuesta al chat',
  "回应速度": 'Velocidad de respuesta',
  "运输方式": 'Método de envío',
  "包裹类目": 'Categoría de paquete',
  "长度": 'Longitud',
  "(CM)": '(CM)',
  "宽度": 'Ancho',
  "高度": 'Altura',
  "重量": 'Peso',
  "(KG)": '(KG)',
  "数量": 'Cantidad',
  "查询": 'Buscar',
  "运费估算": 'Estimación de envío',
  "仓库地址": 'Dirección del almacén',
  "包裹信息": 'Información del paquete',
  "新增包裹": 'Agregar paquete',
  "快递单号": 'Número de seguimiento',
  "货物名称": 'Nombre del producto',
  "货物数量": 'Cantidad de productos',
  "保险服务(申报价值)": 'Servicio de seguro (valor declarado)',
  "最大货值金额": 'Monto máximo del valor de la mercancía',
  "保险费": 'Tarifa de seguro',
  "木架加固服务": 'Servicio de refuerzo con marco de madera',
  "备注": 'Observaciones',
  "操作": 'Acciones',
  "查看禁运物品": 'Ver artículos prohibidos',
  "发货类型": 'Tipo de envío',
  "请选择距您最近的提货仓直发包裹，您会更快的收到包裹。": 'Seleccione el almacén de recogida más cercano para enviar el paquete directamente, lo que le permitirá recibirlo más rápidamente.',
  "选择仓库": 'Seleccionar almacén',
  "注意事项": 'Notas importantes',
  "我已阅读并同意": 'He leído y acepto',
  "包裹转运验货规则和服务协议": 'Reglas y acuerdo de servicios de inspección y reenvío de paquetes',
  "保险服务及条款": 'Servicio de seguro y términos',
  "修改": 'Modificar',
  "提交": 'Enviar',
  "支付密码": 'Contraseña de pago',
  "请输入快递单号": 'Ingrese el número de seguimiento',
  "按城市查询": 'Buscar por ciudad',
  "售后单号": 'Número de servicio postventa',
  "类型": 'Tipo',
  "状态": 'Estado',
  "介入原因": 'Motivo de intervención',
  "选择快递": 'Seleccionar servicio de mensajería',
  "上传凭证": 'Subir comprobante',
  "关闭": 'Cerrar',
  "商品信息": 'Información del producto',
  "退/换货数量": 'Cantidad de devoluciones/cambios',
  "单价": 'Precio unitario',
  "显示全部": 'Mostrar todo',
  "原因": 'Motivo',
  "退款金额": 'Monto del reembolso',
  "返回设置": 'Configuración de devolución',
  "登录密码修改成功": 'Contraseña de inicio de sesión modificada con éxito',
  "支付密码修改成功": 'Contraseña de pago modificada con éxito',
  "手机号修改成功": 'Número de teléfono modificado con éxito',
  "邮箱修改成功": 'Correo electrónico modificado con éxito',
  "新增收货地址": 'Agregar dirección de envío',
  "有效期至": 'Válido hasta',
  "发起配送": 'Iniciar entrega',
  "箱子号": 'Número de la caja',
  "请输入地址": 'Ingrese la dirección',
  "流水号": 'Número de transacción',
  "运费": 'Tarifa de envío',
  "客户代号": 'Código del cliente',
  "提交时间": 'Fecha de envío',
  "预约取货时间": 'Hora programada de recogida',
  "体积": 'Volumen',
  "催单": 'Solicitar rápidamente',
  "全选": 'Seleccionar todo',
  "支付": 'Pagar',
  "发起": 'Iniciar',
  "订单号": 'Número de pedido',
  "售后中": 'Servicio postventa en proceso',
  "取消订单": 'Cancelar pedido',
  "支付订单": 'Pagar pedido',
  "打印标签": 'Imprimir etiqueta',
  "取消标签": 'Cancelar etiqueta',
  "确认签收": 'Confirmar recepción',
  "待补款": 'Pendiente de pago adicional',
  "发起售后": 'Iniciar servicio postventa',
  "订单号/商品名称": 'Número de pedido / Nombre del producto',
  "采购失败等待操作订单": 'Compra fallida, esperando acción',
  "金额": 'Monto',
  "账户余额": 'Saldo de la cuenta',
  "充值": 'Recargar',
  "应付金额": 'Monto a pagar',
  "交易密码": 'Contraseña de transacción',
  "优惠劵": 'Cupón de descuento',
  "线下充值": 'Recarga fuera de línea',
  "汇入银行": 'Transferencia bancaria',
  "充值金额": 'Monto de recarga',
  "充值时间": 'Fecha de recarga',
  "充值证明": 'Comprobante de recarga',
  "支付宝充值": 'Recarga mediante Alipay',
  "币种": 'Moneda',
  "最小充值金额": 'Monto mínimo de recarga',
  "最大充值金额": 'Monto máximo de recarga',
  "手续费": 'Tarifa de procesamiento',
  "Wing充值": 'Recarga de Wing',
  "设为默认": 'Establecer como predeterminado',
  "默认仓库": 'Almacén predeterminado',
  "售后详情": 'Detalles del servicio postventa',
  "请输入姓名": 'Ingrese su nombre',
  "手机号": 'Número de teléfono',
  "请输入手机号": 'Ingrese su número de teléfono',
  "所在地区": 'Ubicación',
  "选择地址": 'Seleccionar dirección',
  "详细住址": 'Dirección detallada',
  "请输入详细住址": 'Ingrese la dirección detallada',
  "地址照片": 'Foto de la dirección',
  "设为默认地址": 'Establecer como dirección predeterminada',
  "订单信息": 'Información del pedido',
  "充值信息": 'Información de recarga',
  "提货信息": 'Información de recogida',
  "是否删除": '¿Eliminar?',
  "查询时间": 'Tiempo de búsqueda',
  "清空": 'Limpiar',
  "详情": 'Detalles',
  "录单时间": 'Hora de registro',
  "商品数量": 'Cantidad de productos',
  "提货方式": 'Método de recogida',
  "合并": 'Combinar',
  "已验证": 'Verificado',
  "物流状态": 'Estado logístico',
  "箱子内含件数": 'Número de artículos en la caja',
  大陆出库时间: 'Tiempo de despacho en China:',
  实重: 'Peso real:',
  计费重量: 'Peso facturable:',
  国际运费: 'Tarifa internacional de envío:',
  其他费用: 'Otros cargos:',
  总金额: 'Total:',
  用户名: 'Nombre de usuario:',
  昵称: 'Apodo:',
  用户代码: 'Código de usuario:',
  生日: 'Fecha de nacimiento:',
  性别: 'Género:',
  男: 'Masculino',
  女: 'Femenino',
  绑定业务员: 'Asociar a un representante de ventas:',
  保存: 'Guardar',
  我的余额: 'Mi saldo:',
  提现: 'Retirar',
  充值记录: 'Historial de recargas:',
  提现记录: 'Historial de retiros:',
  收支明细: 'Detalles de ingresos y gastos:',
  时间: 'Fecha y hora:',
  充值渠道: 'Canal de recarga:',
  提现卡号: 'Número de cuenta para retiro:',
  提现金额: 'Monto a retirar:',
  交易号: 'Número de transacción:',
  收入: 'Ingreso',
  支出: 'Egreso',
  余额: 'Saldo',
  返回我的钱包: 'Volver a mi billetera',
  余额提现: 'Retiro de saldo:',
  输入提现金额: 'Ingrese el monto a retirar:',
  输入您的交易密码: 'Ingrese su contraseña de transacción:',
  请选择出您提现的原因: 'Seleccione la razón para su retiro:',
  可多选: 'Puede seleccionar múltiples opciones',
  上传身份信息: 'Subir información de identidad:',
  验证身份: 'Verificar identidad',
  完成: 'Completar',
  验证码: 'Código de verificación:',
  获取验证码: 'Obtener código de verificación',
  修改登录密码: 'Cambiar contraseña de inicio de sesión:',
  请输入旧密码: 'Ingrese la contraseña anterior:',
  旧密码: 'Contraseña anterior:',
  请输入新密码: 'Ingrese la nueva contraseña:',
  新密码: 'Nueva contraseña:',
  再次输入新密码: 'Ingrese nuevamente la nueva contraseña:',
  设置交易密码: 'Establecer contraseña de transacción:',
  验证方式: 'Método de verificación:',
  设置支付密码: 'Establecer contraseña de pago:',
  确认支付密码: 'Confirmar contraseña de pago:',
  页面维护中: 'Página en mantenimiento',
  实力: 'Fortalezas:',
  服务: 'Servicios:',
  "完善的售后服务体系：时效保证、商品质量跟踪、退换货保障等": 'Sistema de servicio postventa completo: Garantía de tiempo, seguimiento de calidad de productos, garantía de devolución y cambio, etc.',
  优势: 'Ventajas:',
  前往首页: 'Ir a la página principal',
  售后订单号: 'Número de orden postventa:',
  售后订单: 'Orden postventa:',
  发起时间: 'Fecha de inicio:',
  快递公司: 'Empresa de mensajería:',
  商品图片: 'Imagen del producto:',
  商品名称: 'Nombre del producto:',
  话费充值: 'Recarga de saldo:',
  请输入以0开头的手机号码: 'Ingrese un número de teléfono que comience con 0:',
  例如: 'Ejemplo:',
  国际快递: 'Envío internacional:',
  包裹查询: 'Consulta de paquetes:',
  网点查询: 'Consulta de puntos de servicio:',
  如何注册: 'Cómo registrarse:',
  如何充值: 'Cómo recargar:',
  如何提现: 'Cómo retirar fondos:',
  "找到“免费注册”": 'Encuentra "Registro gratuito"',
  填写注册信息: 'Completa la información de registro:',
  "①“用户名”必填,并且唯一。": '① "Nombre de usuario" es obligatorio y único.',
  "② 设定登录密码,密码长度不能小于6位数。": '② Configura la contraseña de inicio de sesión. La longitud de la contraseña no puede ser inferior a 6 dígitos.',
  "③“邮箱”与“手机号”非必填,若填写,则必须验证。": '③ "Correo electrónico" y "Número de teléfono" son opcionales, pero si se ingresan, deben ser verificados.',
  "④“支付密码”选填,密码长度不能小于6位数。": '④ "Contraseña de pago" es opcional. La longitud de la contraseña no puede ser inferior a 6 dígitos.',
  "⑤“业务员”（选填,推荐您的业务员工号,如果没有,可不填）。": '⑤ "Representante de ventas" (opcional, recomiende el número de empleado de su representante de ventas, si no lo tiene, puede dejarlo en blanco).',
  勾选用户注册协议: 'Marca la casilla de acuerdo con el contrato de registro del usuario.',
  "勾选用户注册协议，点击【用户注册协议】，可查看协议内容": 'Marca la casilla de acuerdo con el contrato de registro del usuario y haz clic en [Contrato de registro del usuario] para ver el contenido del contrato.',
  查找商品: 'Buscar productos:',
  加入购物车: 'Agregar al carrito:',
  完成支付: 'Completar pago:',
  进入充值页面: 'Ir a la página de recarga:',
  "②点击“充值提现”,进入账户余额与明细页面": '② Haz clic en "Recargar/Retirar fondos" para ir a la página de saldo y detalles de la cuenta.',
  "③点击账户余额右侧“充值”,进入充值页面": '③ Haz clic en "Recargar" a la derecha del saldo de la cuenta para ir a la página de recarga.',
  充值成功: 'Recarga exitosa:',
  进入提现页面: 'Ir a la página de retiro:',
  '①登录之后,点击位于首页上方用户名,进入订单中心': '① Después de iniciar sesión, haz clic en tu nombre de usuario en la parte superior de la página de inicio para ir al centro de pedidos.',
  '③点击账户余额右侧“提现”,进入提现页面': '③ Haz clic en "Retirar fondos" a la derecha del saldo de la cuenta para ir a la página de retiro.',
  安全设置页面: 'Página de configuración de seguridad:',
  '①在首页顶部点击您已登录的“用户名”': '① En la parte superior de la página de inicio, haz clic en tu "Nombre de usuario" después de iniciar sesión.',
  '②安全设置页面': '② Página de configuración de seguridad:',
  '①点击登录密码后面的“修改密码”': '① Haz clic en "Cambiar contraseña" después de la contraseña de inicio de sesión.',
  '②填写新的登录密码、确认登录密码，点击“修改”': '② Ingresa la nueva contraseña de inicio de sesión, confírmala y haz clic en "Modificar".',
  '③密码修改完成': '③ Cambio de contraseña completado.',
  启用交易密码: 'Habilitar contraseña de transacción:',
  '①点击交易密码后面的“立即启用”': '① Haz clic en "Habilitar ahora" después de la contraseña de transacción.',
  '③密码设置完成': '③ Configuración de contraseña completada.',
  公告: 'Aviso:',
  店铺推荐: 'Tiendas recomendadas:',
  更多: 'Más',
  账号登录: 'Inicio de sesión con cuenta:',
  手机登录: 'Inicio de sesión con teléfono:',
  账号: 'Cuenta:',
  密码: 'Contraseña:',
  记住用户: 'Recordar usuario:',
  忘记密码: '¿Olvidaste tu contraseña?',
  免费注册: 'Registro gratuito:',
  第三方绑定: 'Vinculación de terceros:',
  绑定: 'Vincular',
  小时内: 'En las últimas horas:',
  加入时间: 'Fecha de ingreso:',
  个月前: 'Hace meses:',
  全部商品: 'Todos los productos:',
  下单时间: 'Fecha de pedido:',
  补款: 'Pago adicional:',
  收货人信息: 'Información del destinatario:',
  手机号码: 'Número de teléfono:',
  配送信息: 'Información de envío:',
  付款信息: 'Información de pago:',
  付款方式: 'Método de pago:',
  到齐发货: 'Entregar cuando esté completo:',
  货到即发: 'Envío inmediato al recibir el pago:',
  是否取消: '¿Cancelar?',
  取消补款: 'Cancelar pago adicional:',
  补货款: 'Pago adicional:',
  小计: 'Subtotal:',
  申请平台介入: 'Solicitar intervención de la plataforma:',
  撤销申请: 'Cancelar solicitud:',
  已付金额: 'Monto pagado:',
  补款金额: 'Monto adicional:',
  正在审核: 'En proceso de revisión:',
  确认支付: 'Confirmar pago:',
  确认收货: 'Confirmar recepción:',
  合计: 'Total:',
  留言: 'Mensaje:',
  支付方式: 'Método de pago:',
  '店铺合计(含运费)': 'Total de la tienda (incluido el envío):',
  店铺合计: 'Total de la tienda:',
  查看禁运品: 'Ver productos prohibidos:',
  '商品保险费(非人为破损100%退款)': 'Seguro de productos (reembolso del 100% por daños no intencionales):',
  商品质量保障协议: 'Acuerdo de Garantía de Calidad del Producto',
  是否拆单: '¿Dividir el Pedido?',
  应付总额: 'Total a Pagar',
  分享: 'Compartir',
  收藏商品: 'Guardar Producto',
  找同款: 'Encontrar Productos Similares',
  起批量: 'Cantidad Mínima de Pedido',
  '我们承诺您的交易安全:我们只会在买家收到货后拨款给卖家!': 'Garantizamos la seguridad de su transacción: ¡Solo transferiremos el pago al vendedor después de que el comprador reciba los productos!',
  配送至: 'Enviar a',
  该地址不支持配送: 'Esta dirección no admite entrega',
  商品来自: 'Producto de',
  汇率: 'Tipo de Cambio',
  库存: 'Inventario',
  给客服留言: 'Dejar un Mensaje al Servicio al Cliente',
  总额: 'Total',
  采购清单: 'Lista de Compras',
  已售完: 'Agotado',
  更新商品数据: 'Actualizar Datos del Producto',
  即时聊天: 'Chat en Tiempo Real',
  进入商店: 'Entrar a la Tienda',
  推荐商品: 'Productos Recomendados',
  商品已下架: 'Producto Fuera de Stock',
  '很抱歉，没有淘到该代购商品!': 'Lo sentimos, no se encontró el producto de compra asistida.',
  您可以仔细检查输入的代购链接是否正确: 'Revise cuidadosamente si el enlace de compra asistida ingresado es correcto.',
  打开原链接: 'Abrir Enlace Original',
  暂时不支持该链接: 'Este enlace no es compatible temporalmente',
  设置新密码: 'Configurar Nueva Contraseña',
  验证: 'Verificar',
  请再次输入新密码: 'Ingrese la nueva contraseña nuevamente',
  新密码设置成功: 'Nueva contraseña configurada con éxito',
  立即登录: 'Iniciar Sesión Ahora',
  登录密码: 'Contraseña de Inicio de Sesión',
  请输入登录密码: 'Ingrese la contraseña de inicio de sesión',
  再次输入登录密码: 'Ingrese la contraseña de inicio de sesión nuevamente',
  请再次输入登录密码: 'Ingrese la contraseña de inicio de sesión nuevamente',
  请输入邮箱: 'Ingrese el correo electrónico',
  请输入交易密码: 'Ingrese la contraseña de transacción',
  再次输入交易密码: 'Ingrese la contraseña de transacción nuevamente',
  请输再次输入交易密码: 'Ingrese la contraseña de transacción nuevamente',
  业务员: 'Representante de Ventas',
  用户协议: 'Acuerdo de Usuario',
  LODA隐私权政策: 'Política de Privacidad de LODA',
  ztoworld隐私权政策: 'Política de Privacidad de ztoworld',
  的相关店铺: 'Tiendas Relacionadas',
  已下架: 'Fuera de Stock',
  不满足起批量: 'No cumple con la cantidad mínima de pedido',
  '到底了!': '¡Fin de la Lista!',
  '购物车空空如也，赶紧挑选商品塞满你的购物': 'El carrito de compras está vacío. ¡Agrega productos rápidamente!',
  删除: 'Eliminar',
  all: 'Todos',
  清空购物车: 'Vaciar Carrito',
  最多提交数量: 'Cantidad Máxima para Enviar',
  已选: 'Seleccionado',
  件: 'Artículo(s)',
  我的专属链接: 'Mi Enlace Exclusivo',
  可用余额: 'Saldo Disponible',
  我的优惠卷: 'Mis Cupones',
  热销榜: 'Ranking de Ventas',
  低至: 'Desde',
  货物中心: 'Centro de Mercancías',
  资产中心: 'Centro de Activos',
  我的优惠劵: 'Mis Cupones',
  设置: 'Configuración',
  订单中心: 'Centro de Pedidos',
  手机: 'Teléfono Móvil',
  '备注:以发往海外的时间开始计算，如有节假日，就顺延几天节假日时间。': 'Nota: El tiempo se calcula a partir del envío al extranjero. Si hay días festivos, se pospondrá la fecha de entrega en consecuencia.',
  综合排序: 'Ordenar por Relevancia',
  销量升序: 'Ventas Ascendentes',
  销量降序: 'Ventas Descendentes',
  淘宝天猫: 'Taobao Tmall',
  新品: 'Nuevos Productos',
  销量: 'Ventas',
  全部订单: 'Todos los Pedidos',
  待审核: 'Pendiente de Revisión',
  待付款: 'Pendiente de Pago',
  待订购: 'Pendiente de Pedido',
  待发货: 'Pendiente de Envío',
  待签收: 'Pendiente de Recibir',
  货在中国仓库: 'Mercancía en Almacén en China',
  已发往海外仓库: 'Enviado al Almacén en el Extranjero',
  海外仓库已签收: 'Almacén en el Extranjero ha Recibido',
  客户已收货: 'Cliente ha Recibido el Producto',
  交易完成: 'Transacción Completada',
  作废订单: 'Pedido Anulado',
  商城: 'Centro Comercial',
  话费: 'Recarga de Teléfono',
  等待付款: 'Pendiente de Pago',
  已经付款: 'Pago Realizado',
  正常使用: 'En Uso Normal',
  已经使用: 'Ya Utilizado',
  已经过期: 'Expirado',
  '登录密码能够保证您的账户安全，让您购物更放心': 'La contraseña de inicio de sesión garantiza la seguridad de su cuenta y le permite comprar con confianza',
  '保障资金安全，提款需要设置提款密码': 'Asegura la seguridad de sus fondos. Se requiere una contraseña de retiro para realizar retiros',
  '验证后，可用于快速找回登录密码及交易密码，接收账户余额变动提醒': 'Después de la verificación, se puede utilizar para recuperar rápidamente la contraseña de inicio de sesión y la contraseña de transacción, y recibir alertas sobre cambios en el saldo de la cuenta',
  '绑定邮箱后，可用于找回密码，接收相关邮件': 'Después de vincular el correo electrónico, se puede utilizar para recuperar contraseñas y recibir correos electrónicos relacionados',
  验证手机: 'Verificar Teléfono',
  验证邮件: 'Verificar Correo Electrónico',
  绑定手机号: 'Vincular Número de Teléfono',
  修改手机号: 'Modificar Número de Teléfono',
  已认证手机号: 'Número de Teléfono Verificado',
  绑定邮箱: 'Vincular Correo Electrónico',
  修改邮箱: 'Modificar Correo Electrónico',
  已认证邮箱: 'Correo Electrónico Verificado',
  欢迎使用LODA: 'Bienvenido/a a LODA',
  '这是柬埔寨最大电子商务平台，提供在线购物、外卖、物流快递、本地生活等生活服务，让您随时随地享受便捷生活。':
    'Esta es la plataforma de comercio electrónico más grande de Camboya, que ofrece servicios en línea, entrega a domicilio, logística y servicios locales para que pueda disfrutar de la vida de manera conveniente en cualquier momento y lugar.',
  '立即下载LODA APP': 'Descarga la aplicación LODA ahora mismo',
  热搜词: 'Términos Populares de Búsqueda',
  大家都在买: "Todos Compran",
  修改运输方式: 'Modificar Método de Envío',
  仅自己可见: 'Visible Solo para Mí',
  发往海外: 'Enviado al Extranjero',
  等待收货: 'Esperando la Recepción',
  中中运费: 'Tarifa de Envío Intermedia',
  等待审核: 'Esperando Revisión',
  等待采购: 'Esperando Compra',
  付款成功: 'Pago Exitoso',
  商品出库: 'Producto Fuera del Almacén',
  '请按照示例上传图片，图片不正确或者不清楚都会导致审核过慢或者审核不通过': 'Siga el ejemplo para cargar imágenes. Las imágenes incorrectas o borrosas pueden hacer que la revisión sea lenta o no se apruebe.',
  请输入正确手机号: 'Ingrese un número de teléfono válido',
  我要购物: 'Quiero Comprar',
  继续充值: 'Continuar Recargando',
  'LODA柬埔寨-网上购物平台 | 批发&代购,国际转运&本地快递,外卖订餐,商城': 'LODA Camboya - Plataforma de Compras en Línea | Mayorista y Compras Asistidas, Envíos Internacionales y Entrega Local, Pedidos para Llevar, Centro Comercial',
  重新下单: 'Volver a Hacer Pedido',
  '本地(柬埔寨)': 'Local (Camboya)',
  '海外(中国)': 'Internacional (China)',
  '提交成功!您的充值号为': '¡Envío exitoso! Su número de recarga es',
  大于库存: 'Más que el Inventario',
  我的箱子: 'Mi Caja',
  我的包裹: 'Mi Paquete',
  LODA快递: 'LODA Express',
  '下载LODA APP：柬埔寨最佳在线购物平台': 'Descarga la aplicación LODA: la mejor plataforma de compras en línea de Camboya',
  搜索商品: 'Buscar Producto',
  购物车: 'Carrito de Compras',
  提交订单: 'Enviar Pedido',
  新手引导: 'Guía para principiantes',
  登录: 'Iniciar sesión',
  注册: 'Registrarse',
  找回密码: 'Recuperar contraseña',
  帮助中心: 'Centro de ayuda',
  用户中心: 'Centro de usuarios',
  订单详情: 'Detalles del pedido',
  我的订单: 'Mis pedidos',
  收藏夹: 'Lista de favoritos',
  乐达配送: 'Entrega Loda',
  收货地址: 'Dirección de entrega',
  提货仓库: 'Almacén de recogida',
  我的钱包: 'Mi billetera',
  我的优惠券: 'Mis cupones',
  个人资料: 'Datos personales',
  安全设置: 'Configuración de seguridad',
  邀请好友: 'Invitar amigos',
  消息通知: 'Notificaciones',
  已加入购物车: 'Añadido al carrito',
  '备注 : 以发往国际物流仓的时间开始计算,不包含中国采购时间,如果有节假日,就顺延几天节假日的时间':
    'Nota: Se calcula a partir del tiempo de envío al almacén de logística internacional, no incluye el tiempo de compra en China. Si hay días festivos, se pospondrá según la duración de los días festivos.',
  成功添加: 'Añadido con éxito',
  'Loda是一个真实、可靠、便捷的在线购物平台。我们的服务让您真正实现网购无国界、无时差、无距离。在这里，您可以随时随地轻松网购各式各样的商品。':
    'Loda es una plataforma de compras en línea real, confiable y conveniente. Nuestro servicio le permite realizar compras en línea sin fronteras, sin diferencia horaria y sin distancia. Aquí, puede comprar fácilmente una variedad de productos en cualquier momento y lugar.',
  'Loda与中国多所知名商务机构合作，提供高品质的商品和服务，行业内最低的服务费收取标准。与多家国际快递合作，运费低廉，多种寄送方式。专业的采购团队和仓储管理，完整的客户服务体系。从购买到打包到运输，环环放心，完善的客户服务体系，及时解决您的需求。':
    'Loda colabora con diversas instituciones comerciales reconocidas en China para proporcionar productos y servicios de alta calidad con las tarifas de servicio más bajas de la industria. Colaboramos con múltiples servicios de mensajería internacional para ofrecer tarifas de envío reducidas y diversas opciones de entrega. Contamos con un equipo de compras profesional, gestión de almacenes y un sistema completo de servicio al cliente. Desde la compra hasta el envío, le brindamos tranquilidad y resolvemos sus necesidades de manera oportuna.',
  '代购中国购物网站商品（loda帮您买），手机商城（掌上购物，一站式体验），高新科技的商务模式，专业电子商务人员，提供支持多平台多渠道的商品购买，24小时采购服务。':
    'Compre productos de sitios web de compras en China con el servicio de compras de Loda. Explore la tienda en línea desde su teléfono móvil para una experiencia de compra integral. Adoptamos un modelo de negocios de alta tecnología con personal de comercio electrónico profesional, brindando soporte para la compra de productos en múltiples plataformas y canales las 24 horas del día.',
  '新生儿的成长将会一路遇到许多挫折和坎坷，却总是在困惑中寻找正确的方向，在不断变化中创新和完善。 也许今天的我们并不是令人满意，但是我们会一直努力做个有态度的网购平台。 欢迎大家多多提出对我们的想法和建议，您的需求就是我们成长的动力，我们会不断完善，做个最好的自己。':
    'El crecimiento de los recién nacidos enfrentará muchos desafíos y obstáculos, pero siempre buscarán la dirección correcta en medio de la confusión, innovando y perfeccionándose en un entorno en constante cambio. Quizás no seamos perfectos hoy, pero siempre nos esforzaremos por ser una plataforma de compras en línea con actitud. Les damos la bienvenida a todos a presentar sus ideas y sugerencias. Sus necesidades son nuestra motivación para crecer, y nos esforzaremos continuamente por ser lo mejor.',
  分钟前: 'minutos atrás',
  小时前: 'horas atrás',
  天前: 'días atrás',
  购买过: 'Comprado',
  女装: 'Ropa de mujer',
  母婴: 'Maternidad e infancia',
  美妆: 'Belleza',
  居家日用: 'Artículos para el hogar',
  鞋品: 'Calzado',
  文娱车品: 'Entretenimiento y accesorios para autos',
  数码家电: 'Electrónicos y electrodomésticos',
  男装: 'Ropa de hombre',
  内衣: 'Ropa interior',
  箱包: 'Bolsos',
  配饰: 'Accesorios',
  户外运动: 'Deportes al aire libre',
  家装家纺: 'Decoración del hogar y textiles',
  '到底啦~看看其他精选内容': '¡Eso es todo! Mira otros contenidos seleccionados',
  '很抱歉，该商品为黑名单商品!': 'Lo siento, este producto está en la lista negra.',
  商品名字: 'Nombre del producto',
  '您可以选择其他喜爱商品再次尝试，谢谢。': 'Puede intentar seleccionar otros productos favoritos. Gracias.',
  收起: 'Cerrar',
  取消原因: 'Motivo de cancelación',
  确认删除吗: '¿Confirmar eliminación?',
  '复制成功!快去粘贴发送给您的好友吧': '¡Copiado exitosamente! ¡Ve y pega para enviarlo a tus amigos!',
  密码不一致: 'Contraseñas no coinciden',
  '已发送验证码,请注意查收': 'Código de verificación enviado, por favor, revise su bandeja de entrada',
  默认地址: 'Dirección predeterminada',
  是否跳转到包裹列表: '¿Ir a la lista de paquetes?',
  已复制到粘贴板: 'Copiado al portapapeles',
  点击查看客服联系方式: 'Haga clic para ver la información de contacto del servicio al cliente',
  包邮: 'Envío gratuito',
  您验证的邮箱: 'Su correo electrónico verificado',
  单件: 'Unidad',
  集包: 'Empaquetar',
  请选择业务员: 'Seleccione un representante de ventas',
  '美味佳肴、超市便利': 'Deliciosos platos, conveniencia de supermercado',
  '随时随点随到 尽在LODA APP': 'Entrega en cualquier momento y lugar, todo en la aplicación Loda',
  服务费: "Tarifas de servicio",
  订购中: 'En pedido',
  "已入库（中国仓）": 'Se ha almacenado (almacén de china)',
  国际运输中: 'En el transporte internacional',
  "已入库（印尼仓）": '',
  待收货: 'A recibir',
  售后: 'Posventa',
  请选择省份: 'Por favor, elija la provincia',
  未开通提货仓: 'No se ha abierto el almacén de recogida',
  请选择提货仓库: 'Por favor, elija el almacén de recogida',
  自营: 'Autónomo',
  支付成功: 'Pago exitoso',
  自动跳转登录: 'Inicio de sesión de salto automático',
  休息: '',
  数量必须是: 'La cantidad debe ser un múltiplo de {name}',
  快递: 'Entrega urgente',
  微信充值: 'WeChat',
  '交易密码已锁定': 'La contraseña de la transacción ha sido bloqueada, inténtelo nuevamente después del {name}',
  不能为0: 'no puede ser 0',
  货物类型: 'Tipo de carga',
  请设置提货仓: 'Por favor configure un almacén de entrega',
  配送费: 'Tarifa de Envío',
}